import { Core } from '@pdftron/webviewer';

import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';

import Annotation = Core.Annotations.Annotation;
import AnnotationManager = Core.AnnotationManager;

export const getAnnotationBySnapId = (annotationManager: AnnotationManager, snapId: string, annotLIst?: Annotation[]): Annotation | undefined => {
    const annotationsList = annotLIst || annotationManager.getAnnotationsList()
    
    const annot = annotationsList.find(annot => {
        return annot.getCustomData(CustomDataKey.relatedSnapshotId) === snapId
    })
    
    return annot
}
