import { Core } from '@pdftron/webviewer';

import Annotation = Core.Annotations.Annotation;

import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

import AnnotationManager = Core.AnnotationManager;

import { useDocument } from 'react-firebase-hooks/firestore';
import { useQueryParam } from 'use-query-params';

import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { inputFilesRef, InputFileType } from '@/firestore/api/inputFiles.ts';

import { doc } from 'firebase/firestore';

interface CreateAnnotationsOptions {
    redraw?: boolean
    inputFileType: InputFileType
}

export type CreateAnnotations = (annotations: Annotation[], options: Partial<CreateAnnotationsOptions>) => void

export const createAnnotations = (annotations: Annotation[], annotManager: AnnotationManager, options: CreateAnnotationsOptions) => {
    // Allow annotations only for CY tab
    if(options.inputFileType !== 'currentYearFinancialStatement') return
    
    annotManager.addAnnotations(annotations);

    if(options?.redraw) {
        for(const annotation of annotations) {
            annotManager.redrawAnnotation(annotation);
        }
    }
}

export const useCreateAnnotations = () => {
    const { annotationManager } = useViewerDocument()
    const [activeTab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type)

    const [inputFileSnap, inputFileLoading] = useDocument(doc(inputFilesRef, activeTab || 'none'))
    const inputFileType = inputFileSnap?.data()?.fileType
    
    return (!inputFileLoading && inputFileType && annotationManager) ? (annotations: Annotation[], options: Partial<CreateAnnotationsOptions>) => createAnnotations(
        annotations, 
        annotationManager,
        { inputFileType, ...options }, 
    ) : null
}
