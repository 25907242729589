import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

import { Auth0Provider } from '@auth0/auth0-react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { wrapUseRoutes } from '@sentry/react';
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Route, RouteObject, useNavigate, useRoutes, Routes } from 'react-router-dom';

import { initSentry } from '@/infra/sentry.ts';
import { FileTabs } from '@/widgets/FileTabs';

import { AuthData, AuthDataContext, getAuthConfig } from './components/containers/AuthContext';
import { ROUTES_CONFIG, ROUTES_CONFIG_REG_REP } from './constants/routes.ts';
import { FilesPage } from './pages/FilesPage';
import { AddNewReportModal } from './pages/FilesPage/AddNewReportModal';
import { RegRepNewModal, RegRepPage } from './pages/RegRepPage';
import { ReviewPage } from './pages/ReviewPage';
import { SuggestionsBuilderPage } from './pages/SuggestionsBuilderPage';
import { SupplemendaryFilesPage } from './pages/SupplemendaryFilesPage';
import { LocalErrorBoundary } from './widgets/CriticalErrorPlaceholder/CriticalErrorPlaceholder.tsx';
import { Header } from './widgets/Header/Header.tsx';

export type PdfContext = {
    pdfInstance: WebViewerInstance | null,
    setPdfInstance: Dispatch<SetStateAction<null>>
}
export const PdfViewerContext2 = createContext<PdfContext>(
    {
        pdfInstance: null,
        setPdfInstance: undefined, 
    },
);

const queryClient = new QueryClient()

initSentry()

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
    const navigate = useNavigate()
    const [pdfInstance, setPdfInstance] = useState(null)
    const authData = useContext<AuthData>(AuthDataContext)

    const regRepOn = authData?.company?.data()?.features?.includes('showRegRep') ?? false
    const reviewerOn = authData?.company?.data()?.features?.includes('showReviewer') ?? false

    useEffect(() => {
        if(location.pathname === '/') {
            if(reviewerOn) {
                navigate(ROUTES_CONFIG.REVIEW.path)
            } else if(regRepOn) {
                navigate(ROUTES_CONFIG_REG_REP.REG_REP.path)
            }
        }
    }, [regRepOn, reviewerOn]);
    
    const routes: RouteObject[] = [ ]
        
    if(regRepOn) {
        routes.push( {
            path: ROUTES_CONFIG_REG_REP.REG_REP.path,
            element: <RegRepPage/>,
            children: [
                {
                    path: ROUTES_CONFIG_REG_REP.REG_REP_NEW.path,
                    element: <RegRepNewModal/>,
                },
            ],
        })
    }
    
    if(reviewerOn) {
        routes.push( {
            path: ROUTES_CONFIG.REVIEW_REPORT.path,
            element: <ReviewPage/>,
            children: [
                {
                    path: ROUTES_CONFIG.SUGGESTIONS_BUILDER.path,
                    element: <SuggestionsBuilderPage/>,
                },
            ],
        },
        {
            path: ROUTES_CONFIG.REVIEW.path,
            element: <FilesPage/>,
            children: [
                {
                    path: ROUTES_CONFIG.REVEIW_NEW.path,
                    element: <AddNewReportModal/>,
                },
                {
                    path: ROUTES_CONFIG.REVIEW_EDIT.path,
                    element: <AddNewReportModal/>,
                },
            ],
        },
        {
            path: ROUTES_CONFIG.SUPPLEMENTARY_FILES.path,
            element: <SupplemendaryFilesPage/>,
        })
    }
    
    const ActiveElement = useSentryRoutes(routes);
    
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#0253FF',
                    colorTextBase: '#101828',
                },
            }}
        >
            <Auth0Provider {...(getAuthConfig(navigate))}>
                <PdfViewerContext2.Provider value={{ pdfInstance, setPdfInstance }}>
                    <QueryClientProvider client={queryClient}>
                        <LocalErrorBoundary>
                            <Layout style={{ width: '100%', height: '100%', overflow: 'auto', minWidth: '1000px' }}>
                                <Header/>
                                <Routes>
                                    <Route path={ROUTES_CONFIG.REVIEW_REPORT.path} element={<FileTabs/>}/>
                                </Routes>
                                <Content>
                                    <LocalErrorBoundary>
                                        {ActiveElement}
                                    </LocalErrorBoundary>
                                </Content>
                            </Layout>
                        </LocalErrorBoundary>
                    </QueryClientProvider>
                </PdfViewerContext2.Provider>
            </Auth0Provider>
        </ConfigProvider>
    )
}

export default Sentry.withProfiler(App)
