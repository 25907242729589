import { useContext } from 'react';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { CompanyFeatures } from '@/firestore/api/company.ts';

export const useFeatureOn = (feature: CompanyFeatures): boolean => {
    const authData = useContext<AuthData>(AuthDataContext)

    return authData.company?.data()?.features?.includes(feature) || false
}
