import { useQueryParam } from 'use-query-params';

import {
    AI_CHAT_QUERY_CONFIG,
    IDENTIFY_VALUE_QUERY_CONFIG,
    SUM_SELECT_QUERY_PARAM,
    TICK_MARK_QUERY_CONFIG,
} from '../widgets/MagicButtons/MagicButtons.constants';
import { COMMENT_TOOL_QUERY_CONFIG } from '../widgets/NativeCommentButton';
import { NOTES_LIBRARY_QUERY_CONFIG } from '../widgets/NotesLibrary/NotesLibrary.constants';

export const useMagicTools = () => {
    const tools = [
        {
            queryName: NOTES_LIBRARY_QUERY_CONFIG.name,
            queryObj: useQueryParam(NOTES_LIBRARY_QUERY_CONFIG.name, NOTES_LIBRARY_QUERY_CONFIG.type),
        },
        {
            queryName: AI_CHAT_QUERY_CONFIG.name,
            queryObj: useQueryParam(AI_CHAT_QUERY_CONFIG.name, AI_CHAT_QUERY_CONFIG.type),
        },
        {
            queryName: TICK_MARK_QUERY_CONFIG.name,
            queryObj: useQueryParam(TICK_MARK_QUERY_CONFIG.name, TICK_MARK_QUERY_CONFIG.type),
        },
        {
            queryName: SUM_SELECT_QUERY_PARAM.name,
            queryObj: useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type),
        },
        {
            queryName: COMMENT_TOOL_QUERY_CONFIG.name,
            queryObj: useQueryParam(COMMENT_TOOL_QUERY_CONFIG.name, COMMENT_TOOL_QUERY_CONFIG.type),
        },
        {
            queryName: IDENTIFY_VALUE_QUERY_CONFIG.name,
            queryObj: useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type),
        },
    ];

    const handleTurnOnTool = (queryName: string) => {
        const targetTool = tools.find(tool => tool.queryName === queryName);
        const [targetToolOpen, setTargetToolOpen] = targetTool?.queryObj;

        if (targetToolOpen) {
            // Turn off the target tool
            setTargetToolOpen(false);
        } else {
            // Turn on the target tool and turn off all other tools
            tools.forEach(tool => {
                const [, setter] = tool.queryObj;
                if (tool.queryName === queryName) {
                    // Toggle the selected tool
                    setter(true);
                } else {
                    // Turn off all other tools
                    setter(false);
                }
            });
        }
    };

    return {
        handleTurnOnTool,
        getToolState: (queryName: string) => {
            const tool = tools.find(t => t.queryName === queryName);
            return tool ? tool.queryObj[0] : false;
        },
    };
}; 
