import { QueryCompositeFilterConstraint } from '@firebase/firestore';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { collection, getDoc, getDocs, QueryConstraint, query, doc } from 'firebase/firestore';

import { db } from '@/firestore/config.ts';

import http from './http'
import { UseQueryCustomParams } from '../api.types.ts';

/**
 * No message for fetch because there is a risk to spam user with error messages, and we have screens for crashes
 * Other error messages are optional if required in specific endpoint, in other cases no message would be shown
 */
interface GetRequestQueriesParams {
    url: string
    errorMessageFetchCSV?: string
    errorMessageUpdate?: string
    errorMessageCreate?: string
}

interface FetchItemsParams<T> {
    queryParams?: T
    filterString?: string
    pageLimit?: number
    pageOffset?: number
    id?: number | string
}

/**
 * @todo: Prop command for generating api requests
 * @todo: Validate closing slash '/' in the end of url
 * @todo: Turn off autoupdate by default form make migration more easy
 * @todo: Add flag "isModel" or something like this. For endpoints which is build on database table we have more default functionality.
 * @todo: Implement linking with other fetched data?
 * @todo: How to load items in background without triggering loaders?
 * @todo: Cache works not as expected sometimes and hooks doing requests even if data is cached
 * @todo: Stop doing autoupdate if user logged out or token expired if property "refetchInterval" is used
 * @todo: Sorting props (for tables)
 * @todo: Create hook
 * @todo: Migrate 'filrtersString' to object (it can me new method fro filters 'toObject')
 * @todo: Pause react-query refetch in background when tab is not active
 */
export const getRequestQueries = <ResultItem, GetItemsQueryParams>(params: GetRequestQueriesParams) => {
    const { url, errorMessageUpdate, errorMessageCreate } = params

    const commonHeaders = {
        'react-query': 'true',
    }

    async function fetchItems ({ queryParams, filterString, pageLimit, pageOffset, id }: FetchItemsParams<GetItemsQueryParams>) {
        const paginationParams: Record<string, number> = { }

        if (pageOffset >= 0 && pageLimit >= 0) {
            paginationParams.__limit = pageLimit
            paginationParams.__offset = pageOffset
        }

        let requestUrl = `${url}/` + (id ? `${id}/` : '')
        requestUrl = filterString ? `${requestUrl}?${filterString}` : requestUrl

        const response = await http.get<ResultItem[]>(requestUrl, {
            params: {
                ...queryParams,
                ...paginationParams,
            },
            headers: commonHeaders,
        })

        // Wrapping in 'Array' for make typings compatible for case with 'id' and without
        return id ? [response.data] as unknown as ResultItem[] : response.data
    }

    async function fetchFBItems (params: UseFBQueryCustomParams) {
        const { id, filters } = params

        // Firebase logic
        if (id) {
            const docRef = doc(url, id)
            const docSnap = await getDoc(docRef)
            return [{ id: docSnap.id, ...docSnap.data() }]
        }

        const collectionRef = collection(db, url)

        const querySnapshot = await getDocs(
            filters ? query(collectionRef, filters) : collectionRef,
        )

        let errorShown = false

        const items = querySnapshot.docs.map(doc => {
            const data = doc.data()
            if ('id' in data && !errorShown) {
                console.error(
                    `Document in collection "${url}" with ID "${doc.id}" contains 'id' field in its data. ` +
                    'This might cause conflicts as the document ID will overwrite this field.',
                )
                errorShown = true
            }
            return { id: doc.id, ...data }
        })

        return items
    }

    async function fetchItemById ({ id }) {
        const requestUrl = `${url}/${id}/`

        const response = await http.get<ResultItem>(requestUrl, {
            headers: commonHeaders,
        })

        return response.data
    }

    async function updateItemById ({ id, updatedItemData }) {
        const requestUrl = `${url}/${id}/`

        const response = await http.patch<ResultItem>(
            requestUrl,
            updatedItemData,
            {
                meta: {
                    errorToast: errorMessageUpdate,
                },
                headers: commonHeaders,
            },
        )

        return response.data
    }

    function useItemsQuery (
        customParams?: UseQueryCustomParams<GetItemsQueryParams>,
        queryOptions?: Omit<UseQueryOptions<Promise<ResultItem[]>, AxiosError, ResultItem[]>, 'queryKey'>,
    ) {
        const { pageKey, queryParams, filter, pageLimit, pageOffset, id } = customParams ?? {}

        const queryOptionsUpdated: UseQueryOptions<Promise<ResultItem[]>, AxiosError, ResultItem[]> = {
            // @ts-expect-error Can't figure out how to solve types conflict in this case
            initialData: [],
            ...(queryOptions ?? {}),
        }

        const filterString = filter?.URLSearchParams.toString() ?? ''

        return useQuery <Promise<ResultItem[]>, AxiosError, ResultItem[]>({
            queryKey: [url, pageKey, queryParams, filterString, pageOffset, pageLimit],
            queryFn: () => fetchItems({ queryParams, filterString, pageOffset, pageLimit, id }),
            ...queryOptionsUpdated,
        })
    }

    // Add new interface for FB custom params
    interface UseFBQueryCustomParams {
        filters?: QueryCompositeFilterConstraint
        id?: string | number
    }

    function useFBItemsQuery (
        customParams?: UseFBQueryCustomParams,
        queryOptions?: Omit<UseQueryOptions<Promise<ResultItem[]>, AxiosError, ResultItem[]>, 'queryKey'>,
    ) {
        const params = customParams || {}
        
        const queryOptionsUpdated: UseQueryOptions<Promise<ResultItem[]>, AxiosError, ResultItem[]> = {
            // @ts-expect-error Can't figure out how to solve types conflict in this case
            initialData: [],
            ...(queryOptions ?? {}),
        }

        return useQuery<Promise<ResultItem[]>, AxiosError, ResultItem[]>({
            queryKey: [params],
            queryFn: () => fetchFBItems({ ...params }),
            ...queryOptionsUpdated,
        })
    }

    function useItemByIdQuery (
        id: string | number,
        queryOptions?: Omit<UseQueryOptions<Promise<ResultItem>, AxiosError, ResultItem>, 'queryKey'>,
    ) {
        const queryOptionsUpdated: Omit<UseQueryOptions<Promise<ResultItem>, AxiosError, ResultItem>, 'queryKey' > = {
            ...(queryOptions ?? {}),
        }

        return useQuery <Promise<ResultItem>, AxiosError, ResultItem>({
            queryKey: [url, id],
            queryFn: () => fetchItemById({ id }),
            ...queryOptionsUpdated,
        })
    }

    const useItemUpdateMutation = () => {
        const queryClient = useQueryClient()

        return useMutation({
            mutationFn: updateItemById,
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [url] })
            },
        })
    }

    const createItem = async (data: ResultItem) => {
        const response = await http.post<ResultItem>(
            url + '/',
            data,
            {
                meta: {
                    errorToast: errorMessageCreate,
                },
                headers: commonHeaders,
            },
        )

        return response.data
    }

    const useItemCreateMutation = () => {
        const queryClient = useQueryClient()

        return useMutation({
            mutationFn: createItem,
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [url] })
            },
        })
    }

    return {
        useItemsQuery,
        useFBItemsQuery,
        useItemByIdQuery,
        useItemUpdateMutation,
        useItemCreateMutation,
    }
}
