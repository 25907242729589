import { useEffect, useState } from 'react';

export const useIsScrolling = (instance: any, debounceDelay: number = 150): boolean => {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        if (!instance) return;

        const onScrollStart = () => {
            setIsScrolling(true);
        };

        const onScrollEnd = () => {
            setIsScrolling(false);
        };

        const viewerElement = instance?.Core?.documentViewer?.getScrollViewElement();

        if (viewerElement) {
            viewerElement.addEventListener('scroll', onScrollStart);

            let timeout: NodeJS.Timeout;

            const debouncedScrollHandler = () => {
                clearTimeout(timeout);
                timeout = setTimeout(onScrollEnd, debounceDelay);
            };

            viewerElement.addEventListener('scroll', debouncedScrollHandler);

            return () => {
                viewerElement.removeEventListener('scroll', onScrollStart);
                viewerElement.removeEventListener('scroll', debouncedScrollHandler);
            };
        }
    }, [instance, debounceDelay]);

    return isScrolling;
};
