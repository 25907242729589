export enum CustomDataKey {
    /**
     * TickMark confidence
     */
    tickConfidence = 'tickConfidence',
    /**
     * Custom tool name
     */
    toolName = 'toolName',
    /**
     * Snapshot in FB
     */
    relatedSnapshotId = 'relatedSnapshotId',
    relatedStep = 'relatedStep',
    /**
     * Unique id for custon CrossLInk annotation
     */
    linkGroup = 'linkGroup',
    /**
     * Will be shown on hover (comment)
     */
    linkLabel = 'linkLabel',
    /**
     * LInks have a different filed for snapshot id
     * TODO: Use relatedSnapshotId instead
     */
    relatedLinkSnapshotId = 'relatedLinkSnapshotId',
    /**
     * Link indes in the group
     */
    linkIndex = 'linkIndex',
    /**
     * Won't be binded to any step (shown on all steps)
     */
    ignoreStepKey = 'ignoreStepKey',
    /**
     * Trigger custom events (TickMark, Sumselect) only when annotation is created
     */
    annotationOnlyWhenCreate = 'annotationOnlyWhenCreate',
    /**
     * Skip all handling
     */
    skipEventHandlers = 'skipEventHandlers',
    /**
     * Normalized contend inside the annotation
     */
    normalizedValue = 'normalizedValue',
    /**
     * Original content inside the annotation
     */
    originalValue = 'originalValue',
    /**
     * Identifies annotation type
     */
    annotationVariant = 'annotationVariant',

    crossLInkType = 'crossLInkType',

    snapshotRerenderKey = 'snapshotRerenderKey',
    /**
     * Do not considered as created and will be overwritten an annotations renderer logic besed on FB item
     * Need this to keep annotataion creations in one place 
     */
    temporary = 'temporary',
}
