import React, { useContext, useEffect, useState } from 'react'

import { FloatButton } from 'antd';
import { doc, getDoc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { inputFilesRef, InputFile } from '@/firestore/api/inputFiles.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { useMagicTools } from '@/hooks/useMagicTools.ts';
import { IdentifyValue } from '@/widgets/IdentifyValue';
import { COMMENT_TOOL_QUERY_CONFIG, NativeCommentButton } from '@/widgets/NativeCommentButton';

import {
    AI_CHAT_QUERY_CONFIG,
    IDENTIFY_VALUE_QUERY_CONFIG,
    SUM_SELECT_QUERY_PARAM,
    TICK_MARK_QUERY_CONFIG,
} from './MagicButtons.constants.ts';
import { MagicButtonsProps } from './MagicButtons.types'
import { AiChat } from '../../pages/ReviewPage';
import { NOTES_LIBRARY_QUERY_CONFIG } from '../NotesLibrary/NotesLibrary.constants.ts';
import { SumSelect } from '../SumSelect';

export const MagicButtons = (props: MagicButtonsProps) => {
    const noteLibraryOpenParam = useQueryParam(NOTES_LIBRARY_QUERY_CONFIG.name, NOTES_LIBRARY_QUERY_CONFIG.type)
    const iaChatOpenParam = useQueryParam(AI_CHAT_QUERY_CONFIG.name, AI_CHAT_QUERY_CONFIG.type)
    const tickMarkOpenParam = useQueryParam(TICK_MARK_QUERY_CONFIG.name, TICK_MARK_QUERY_CONFIG.type)
    const sumSelectOpenParam = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const commentToolOpenParam = useQueryParam(COMMENT_TOOL_QUERY_CONFIG.name, COMMENT_TOOL_QUERY_CONFIG.type)
    const identifyValOpenParam = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)
    const authData = useContext<AuthData>(AuthDataContext)

    const { id: docId } = useParams()
    const [reportSnapshot ] = useDocument<ReportReview>(doc(reportReviewRef, docId))
    const reportData = reportSnapshot?.data()
    
    const { handleTurnOnTool } = useMagicTools()

    const withVelt = authData.company?.data()?.features?.includes('velt')

    const [tabParam] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);

    const [inputFile, setInputFile] = useState<InputFile | null>(null);

    useEffect(() => {
        const fetchInputFile = async () => {
            if (tabParam) {
                const docRef = doc(inputFilesRef, tabParam);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setInputFile(docSnap.data() as InputFile);
                }
            }
        };

        fetchInputFile();
    }, [tabParam]);

    const currentYearTabActive = inputFile?.fileType !== 'currentYearFinancialStatement';

    if(reportData?.reviewStatus !== 'inProgress') {
        return null
    }

    return (
        <>
            {/*TODO: Finish. Tickmark color picker base*/}
            {/*<Flex*/}
            {/*    style={{ position: 'absolute', top: 100, right: 24 }}*/}
            {/*    vertical*/}
            {/*    gap={8}*/}
            {/*>*/}
            {/*    <Button type='primary' shape='circle' icon={<SearchOutlined/>}/>*/}
            {/*    <Button type='primary' shape='circle' icon={<SearchOutlined/>}/>*/}
            {/*    <Button type='primary' shape='circle' icon={<SearchOutlined/>}/>*/}
            {/*</Flex>    */}
            
            <FloatButton.Group
                shape='square'
                style={{ position: 'absolute' }}
            >
                {!currentYearTabActive && (
                    <IdentifyValue
                        onClick={() => {
                            handleTurnOnTool(IDENTIFY_VALUE_QUERY_CONFIG.name)
                        }}
                    />
                )}
                {/*<TickMark*/}
                {/*    onClick={() => {*/}
                {/*        setIsNotesLibraryOpen(false)*/}
                {/*        setIsAiChatOpen(false)*/}
                {/*        setIsSumSelectOpen(false)*/}
                {/*        setIsCommentToolOpen(false)*/}
                {/*        setIdentifyValOpen(false)*/}
                {/*    }}*/}
                {/*/>*/}
                {!currentYearTabActive && (
                    <SumSelect
                        onClick={() => {
                            handleTurnOnTool(SUM_SELECT_QUERY_PARAM.name)
                        }}
                    />
                )}
                {!currentYearTabActive && (
                    withVelt ? (
                        null
                        // <VeltCommentTool
                        //     onCommentModeChange={(mode: boolean) => {
                        //         if (mode) {
                        //             setIsVeltButtonActive(true)
                        //         } else {
                        //             setIsVeltButtonActive(false)
                        //         }
                        //
                        //         setTickMarkOpen(false)
                        //     }}
                        // >
                        //     <div slot='button'>
                        //         <FloatButton
                        //             shape='square'
                        //             tooltip='Add Comment'
                        //             type={isVeltButtonActive ? 'primary' : 'default'}
                        //             icon={<MessageOutlined/>}
                        //         />
                        //     </div>
                        // </VeltCommentTool>
                    ) : (
                        <NativeCommentButton
                            onClick={() => handleTurnOnTool(COMMENT_TOOL_QUERY_CONFIG.name)}
                        />
                    )
                )}
                {/*{!currentYearTabActive && (*/}
                {/*    <FloatButton*/}
                {/*        type={noteLibraryOpenParam[0] ? 'primary' : 'default'}*/}
                {/*        icon={<ProfileOutlined/>}*/}
                {/*        ooltip='Notes Library'*/}
                {/*        onClick={() => handleTurnOnTool(NOTES_LIBRARY_QUERY_CONFIG.name)}*/}
                {/*    />*/}
                {/*)}*/}
                <AiChat
                    onClick={() => handleTurnOnTool(AI_CHAT_QUERY_CONFIG.name)}
                />
            </FloatButton.Group>
        </>
    )
}
