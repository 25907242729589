import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface MathValidation {
    companyId: string;
    reportId: string;
    entityId: string;
    
    /**
     * Related to "reportExtractedValues" collection
     */
    extractedValueId: string;

    /**
         * IDs of the component values that should sum up to the total
         */
    components: string[];
    /**
         * Expected sum of all components
         */
    expectedValue: number;
    /**
         * Actual sum calculated from components
         */
    actualValue: number;

    valid: boolean
    type: 'sum'
}

export const MATH_VALIDATION_COLLECTION = 'mathValidation'

export const mathValidationRef = collection(db, MATH_VALIDATION_COLLECTION)
