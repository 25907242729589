import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { CreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { getMiddleRightCoordinates } from '@/utils/pdfViewer/getMiddleRightCoordinates.ts';

interface AddCommentForAnnotationParams {
    instance: WebViewerInstance,
    annotationManager: Core.AnnotationManager,
    annotation: Core.Annotations.Annotation,
    commentText: string,
    /**
     * Add without focus or opening notes panel
     */
    silentAdd?: boolean,
}

export const addCommentForAnnotation = async ({ 
    annotationManager, 
    annotation, 
    commentText,
    instance,
    silentAdd = false,
}: AddCommentForAnnotationParams) => {
    const prevContent = annotation.getContents()

    // Adding to content for heaving hover effect
    const resText = prevContent?.length > 0 ? `${prevContent}\n\n${commentText}` : commentText
    annotation.setContents(resText)

    if(!silentAdd) {
        annotationManager.deselectAllAnnotations()
        annotationManager.jumpToAnnotation(annotation);
        annotationManager.selectAnnotation(annotation);
    }
    
    annotationManager.trigger('annotationChanged', [[annotation], 'modify', { imported: false, force: true }]);
    
    if(!silentAdd) {
        instance.UI.openElements(['notesPanel']);
    }
}

export const addAnnotationWithComment = async (
    pdfInstance: WebViewerInstance,
    annotationManager: Core.AnnotationManager,
    userName, text: string,
    annotationPage?: number,
    elementCoords?: {x: number, y: number},
    createAnnotations?: CreateAnnotations,
) => {
    annotationManager.setCurrentUser(userName)

    const elementCoordinates = elementCoords || await getMiddleRightCoordinates(pdfInstance, annotationPage)

    const newAnnotationObj = new pdfInstance.Core.Annotations.StickyAnnotation();

    newAnnotationObj.PageNumber = annotationPage ?? pdfInstance.Core.documentViewer.getCurrentPage();
    newAnnotationObj.X = elementCoordinates.x;
    newAnnotationObj.Y = elementCoordinates.y;

    newAnnotationObj.Author = userName;

    if(!createAnnotations) {
        console.error('createAnnotations is not provided')
    }

    createAnnotations?.([newAnnotationObj], { redraw: true })

    addCommentForAnnotation({
        annotationManager,
        annotation: newAnnotationObj,
        commentText: text,
        instance: pdfInstance,
    })
}
