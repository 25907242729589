import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId.ts';

export const deleteAnnotationByRelatedSnapId = (annotationManager, snapId: string) => {
    const annot = getAnnotationBySnapId(annotationManager, snapId)

    if (annot) {
        annotationManager.deleteAnnotation(annot, {
            force: true,
        })
    }
}
