import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export type CompanyFeatures =
    'velt' | // Use Velt Features
    'showRegRep' | // RegRep Tab available
    'showReviewer' | // Reviewer Tab available
    'funds' | // Turn on funds processing
    'annotationContext' | // Annotations context with new ticks
    'tableDebug' | // Debug tables extraction
    'showLegacyValues' // Legacy identified blocks
    | 'hideCommertialCompany' // Hide commertial company
    | 'showUnknownEntity' // Allos unknown entity type report

export interface Company {
    name: string
    features?: CompanyFeatures[]
}

export const companyRef = collection(db, 'company')
