import { useEffect, useRef } from 'react';

import { getPerformance, trace } from 'firebase/performance';

import { app } from '@/firestore/config';

interface UsePerformanceTraceOptions {
    // Custom attributes to add to the trace
    attributes?: Record<string, string>;
    // Whether to start the trace immediately
    startImmediately?: boolean;
    active?: boolean;
}

export const usePerformanceTrace = (traceName: string, options: UsePerformanceTraceOptions = {}) => {
    // Store trace instance in a ref to persist between renders
    const traceRef = useRef<ReturnType<typeof trace>>();
    const traceStopped = useRef(false)
    const { attributes = {}, startImmediately = true, active = true } = options;

    useEffect(() => {
        if(!active) return 
        // Get performance instance
        const perf = getPerformance(app);
        // Create new trace
        const newTrace = trace(perf, traceName);

        // Add custom attributes if provided
        Object.entries(attributes).forEach(([key, value]) => {
            newTrace.putAttribute(key, value);
        });

        traceRef.current = newTrace;

        // Start trace if startImmediately is true
        if (startImmediately) {
            newTrace.start();
        }

        // Cleanup function to stop trace
        return () => {
            if (traceRef.current && !traceStopped.current) {
                traceRef.current.stop();
                traceRef.current = undefined;
                traceStopped.current = true
            }
        };
    }, [traceName, JSON.stringify(attributes), startImmediately, active]);

    // Return methods to control the trace
    return {
    // Start the trace manually
        startTrace: () => traceRef.current?.start(),
        // Stop the trace manually
        stopTrace: () => {
            if(traceStopped.current) return
            traceRef.current?.stop()
            traceStopped.current = true
        },
        // Add a custom metric
        putMetric: (metricName: string, value: number) => 
            traceRef.current?.putMetric(metricName, value),
        // Add a custom attribute
        putAttribute: (name: string, value: string) => 
            traceRef.current?.putAttribute(name, value),
    };
}; 
