import React, { useContext } from 'react'

import { addDoc } from '@firebase/firestore';
import { Button, Flex, Tag } from 'antd';
import { message } from 'antd/lib';
import { useParams } from 'react-router-dom';

import { PdfViewerContext2 } from '@/App.tsx';
import { StyledText } from '@/components/StyledText';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { addAnnotationWithComment, addCommentForAnnotation } from '@/utils/pdfViewer/addAnnotationWithComment.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { toCamelCase } from '@/utils/text/toCamelCase.ts';

import { StepMessageItemProps } from './StepMessageItem.types'
import { AuthData, AuthDataContext } from '../../../../../../components/containers/AuthContext';
import { resolvedCommentRef } from '../../../../../../firestore/api/resolvedComments.ts';
import { TAG_COLOR_BY_ERROR_TYPE } from '../../../../../../firestore/api/reviewStepComment.ts';

export const StepMessageItem = (props: StepMessageItemProps) => {
    const { data, id, blockCoordinates, blockAnnotation } = props
    const { id: reportOnReviewId } = useParams()
    const { annotationManager } = useViewerDocument()

    const authData = useContext<AuthData>(AuthDataContext)

    const { pdfInstance } = useContext(PdfViewerContext2)

    const createAnnotations = useCreateAnnotations()
    
    const handleAddComment = async () => {
        if(!data.suggestedMessage?.length && !data.explanation?.length) {
            console.error('No suggestedMessage or explanation')
            return
        }
        
        const annotationMessage = (data.suggestedMessage || data.explanation)
            .replace(/<[^>]*>/g, '')

        // Increment page number by 1 because PDF lib 0-based
        const annotationPage = Number.isInteger(data.pageNumber) ? data.pageNumber + 1 : undefined

        const commentGap = 4
        const commentElemHeight = 40 // For centering comment element
        const elementCoords = blockCoordinates ? {
            x: blockCoordinates[0] + blockCoordinates[2] + commentGap ,
            y: blockCoordinates[1] + (blockCoordinates[3] / 2) - (commentElemHeight / 2) + 3, // 3 is a magic number for better centering
        } : undefined
        
        if( blockAnnotation ) {
            addCommentForAnnotation({
                annotationManager,
                annotation: blockAnnotation,
                commentText: annotationMessage,
                instance: pdfInstance,
            })
        } else {
            addAnnotationWithComment(
                pdfInstance, 
                annotationManager,
                authData.user.displayName, 
                annotationMessage, 
                annotationPage, 
                elementCoords,
                createAnnotations,
            )
        }
        
        await addDoc(resolvedCommentRef, {
            commentId: id,
            status: 'applied',
            
            companyId: authData.company.id,
            reportOnReviewId,
        })
        
        message.success('Comment applied')
    }

    const handleReject = async () => {
        await addDoc(resolvedCommentRef, {
            commentId: id,
            status: 'rejected',
            
            companyId: authData.company.id,
            reportOnReviewId,
        })
    }

    if(!data.suggestedMessage?.length && !data.explanation?.length) return null

    return (
        <Flex
            vertical gap={8}     
            style={{
                padding: '8px',
                border: 'solid 1px rgb(240, 240, 240)',
                borderRadius: '8px',
            }}
        >
            <StyledText>{data.suggestedMessage}</StyledText>
            <Flex align='center' justify='space-between'>
                {data.errorType && (
                    <Tag color={TAG_COLOR_BY_ERROR_TYPE[data.errorType]} style={{ width: 'fit-content' }}>{toCamelCase(data.errorType)}</Tag>
                )}
                <Flex align='center'>
                    <Button
                        type='link' danger onClick={handleReject}
                        style={{ color: '#bdb4b4' }}
                    >Hide</Button>
                    <Button type='link' onClick={handleAddComment}>
                        Accept
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}
