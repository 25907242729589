import React, { CSSProperties, ReactNode, useContext, useEffect, useRef, useState } from 'react'

import {
    CalculatorOutlined,
    CopyOutlined,
    DeleteOutlined,
    GatewayOutlined,
    ThunderboltFilled,
} from '@ant-design/icons';
import { useAsyncEffect } from 'ahooks';
import { Button, CollapseProps, Flex, Result, Typography, Tooltip, Spin } from 'antd';
import { Collapse, message } from 'antd/lib';
import { doc, query, where, and, updateDoc, getDoc } from 'firebase/firestore';
import { useCollection, useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { COLORS } from '@/constants/colors.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { mathValidationRef } from '@/firestore/api/mathValidation';
import { ReportExtractedValues, reportExtractedValuesRef , useReportExtractedValuesQuery } from '@/firestore/api/reportExtractedValues.ts';
import { resolvedCommentRef } from '@/firestore/api/resolvedComments.ts';
import { ReviewStepComment, reviewStepCommentRef } from '@/firestore/api/reviewStepComment.ts';
import {
    VALIDATION_BY_VALUE_TYPE, ValueValidation, valueValidationRef,
    ValueValidationType, valueValidationTypes,
} from '@/firestore/api/valueValidation.ts';
import { useMagicTools } from '@/hooks/useMagicTools';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import {
    ConfidencePicker,
    FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM,
    IcValidatioin,
    StepMessageItem,
} from '@/pages/ReviewPage';
import {
    getValidationValueConfColor,
} from '@/pages/ReviewPage/GuidePanel/Steps/StepContent/ExtractedValuesBlock/ExtractedBlockItem';
import { deleteAnnotationByRelatedSnapId } from '@/utils/pdfViewer/deleteAnnotationByRelatedSnapId.ts';
import { IDENTIFY_VALUE_QUERY_CONFIG, SUM_SELECT_QUERY_PARAM } from '@/widgets/MagicButtons/MagicButtons.constants.ts';
import { AnnotationVariant, getAnnotationConfigByVariant } from '@/widgets/PdfViewer2';
import { SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG } from '@/widgets/SumSelect/SumSelect.contants.ts';

import styles from './AnnotationContext.module.scss';
import { AnnotationContextProps } from './AnnotationContext.types'
import { MathValidation } from './MathValidation/MathValidation';

let prevSelected = []

export const AnnotationContext = (props: AnnotationContextProps) => {
    const { annotationManager, pdfInstance } = useViewerDocument()

    const [focusedSnapIdParam, setFocusedSnapIdParam] = useQueryParam(FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.name, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)
    const [identifyValOpen, setIdentifyValOpen] = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)
    
    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()

    const lastSelectedAnnotation = useRef(focusedSnapIdParam)

    const [value, setValue] = useState()
    const [isNumber, setIsNumber] = useState(false)
    const [focusedAnnotationObj, setFocusedAnnotObj] = useState<pdfInstance.Core.Annotations.Annotation | undefined>()

    const annotationId = focusedSnapIdParam ?? lastSelectedAnnotation.current

    // Update styles for selected
    useEffect(() => {
        if(!annotationManager) return
        
        if(prevSelected.length) {
            prevSelected.forEach(annot => {
                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].Opacity
                annot.StrokeColor = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].StrokeColor

                annotationManager.redrawAnnotation(annot)
            })
            prevSelected = []
        }
        if(annotationId) {
            const annot = focusedAnnotationObj
            if(annot) {
                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueFocused].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueFocused].Opacity
                annot.StrokeColor = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueFocused].StrokeColor

                annotationManager.redrawAnnotation(annot)
                    
                prevSelected.push(annot)
            }
        } 
    }, [annotationId, annotationManager, focusedAnnotationObj]);
    
    const [extractedValuesSnap, extractedValueLoading] = useDocument<ReportExtractedValues>(annotationId ? doc(reportExtractedValuesRef, annotationId) : undefined)

    // FIXME: Add cache invalidation
    const reportExtractedValuesQuery = useReportExtractedValuesQuery(
        {
            filters: and(
                where('companyId', '==', authData.company.id),
                where('reportId', '==', docId),
            ),
        },
        {
            enabled: Boolean(docId && authData.company.id),
        },
    );

    const [reviewStepCommentSnap, reviewStepCommentLoading] = useCollection<ReviewStepComment>(query(
        reviewStepCommentRef,
        // None needed just for handling empty value correctly
        and(
            where('extractedValueId', '==', annotationId || 'none'),
        ),
    ))

    const [resolvedCommentsData, resolvedCommentsLoading] = useCollectionData(
        query(resolvedCommentRef, where('reportOnReviewId', '==', docId)),
    )

    const filteredMessageStaps = reviewStepCommentSnap?.docs?.filter(commentDoc => {
        // Check if the comment has been resolved
        const isResolved = resolvedCommentsData?.some(
            resolvedComment => 
                resolvedComment.commentId === commentDoc.id &&
                ( resolvedComment.status === 'rejected' || resolvedComment.status === 'applied'),
        );
        // Only include comments that haven't been resolved
        return !isResolved;
    });

    const [mathValidationsSnap, mathValidationLoading] = useCollection(
        query(
            mathValidationRef,
            and(
                where('extractedValueId', '==', annotationId || 'none'),
                where('type', '==', 'sum'),
            ),
        ),
    );

    useEffect(() => {
        if(!focusedSnapIdParam) return
        lastSelectedAnnotation.current = focusedSnapIdParam
    }, [focusedSnapIdParam]);

    useEffect(() => {
        if(!annotationId || !annotationManager) return

        const annot = annotationManager.getAnnotationsList().find(annot => {
            return annot.getCustomData(CustomDataKey.relatedSnapshotId) === annotationId
        })
        
        if(annot) {
            setFocusedAnnotObj(annot)
        } else {
            // Fixme: Create here an annotation (it propably is not created because of laze loading)
            // console.error('Annotation not found', annotationId)
        }
    }, [annotationId, annotationManager]);
    
    useEffect(() => {
        if (!annotationManager || !annotationId || extractedValueLoading) return
        
        const dataStap = extractedValuesSnap
        const data = dataStap?.data()

        if (!data) return;

        const normValue = data.originalValue

        setValue(normValue)

        const isNumberType = data.normalizedValue !== undefined && data.normalizedValue !== null && (data.type === 'number' || data.type === 'money')

        setIsNumber(isNumberType)
    }, [annotationManager, annotationId, extractedValuesSnap, extractedValueLoading]);

    const itemStyle : CSSProperties = {
        borderRadius: 0,
        cursor: 'default',
    }
    
    const [sumSelectOpen] = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const [, setSumSelectTotalMode] = useQueryParam<boolean>(SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.name, SUM_SELECT_COMPARE_TO_VALUE_QUERY_CONFIG.type)

    const messagesByTick = valueValidationTypes.reduce((acc, type) => {
        const messages = filteredMessageStaps?.filter(doc => doc.data().validationType === type)
        if(messages?.length) {
            acc[type] = messages
        }
        return acc
    }, {})

    const messagesValidationType = filteredMessageStaps?.filter(doc => !doc.data().validationType)
    messagesByTick['other'] = messagesValidationType
    
    const isMaLoading = extractedValueLoading || reviewStepCommentLoading || reportExtractedValuesQuery.isLoading || mathValidationLoading || resolvedCommentsLoading
    
    const { handleTurnOnTool } = useMagicTools()

    const collapseContent: Record<ValueValidationType, ReactNode> = {
        ma: isMaLoading ? <Spin size='default'/> : (isNumber) ? (
            <Flex vertical gap={8}>
                {mathValidationsSnap?.docs.length ? (
                    <Flex gap={4}>
                        <Tooltip placement='left' title='Results of the validation generated by the totals validation tool'><CalculatorOutlined style={{ color: '#13C2C2' }}/></Tooltip>
                        <Typography.Text strong>Totals Validation</Typography.Text>
                    </Flex>
                ) : null}
                {mathValidationsSnap?.docs.map((doc) => (
                    <MathValidation key={doc.id} validationSnap={doc} allComponents={reportExtractedValuesQuery.data}/>
                ))}
                {(!mathValidationsSnap?.docs?.length && !messagesByTick['ma']?.length) && (
                    <Flex justify='center'>
                        <Button 
                            type={sumSelectOpen ? 'primary' : 'default'}
                            onClick={() => {
                                handleTurnOnTool(SUM_SELECT_QUERY_PARAM.name)
                                setSumSelectTotalMode(true)
                            }}
                        >
                        Validate With SumSelect
                        </Button>
                    </Flex>
                )}
            </Flex>
        ) : null,
        py: null,
        ic: <IcValidatioin/>,
    }
    
    const getMessages = (messages: ReviewStepComment[]) => {
        const res = messages?.map((message) => {
            return (
                <StepMessageItem
                    data={message.data() as ReviewStepComment}
                    id={message.id}
                    blockAnnotation={focusedAnnotationObj}
                />
            ) || []
        })
        
        return res
    }

    const valueData = extractedValuesSnap?.data()
    
    let validationTypesByValueType = VALIDATION_BY_VALUE_TYPE[valueData?.type] || valueValidationTypes

    // Exclude ma if values is not a number
    const excludeMa = valueData?.normalizedValue === null || isNaN(valueData?.normalizedValue)

    if(excludeMa) {
        validationTypesByValueType = validationTypesByValueType.filter(el => el !== 'ma')
    }

    const excludeOther = !messagesValidationType?.length

    if(excludeOther) {
        validationTypesByValueType = validationTypesByValueType.filter(el => el !== 'other')
    }

    const items: CollapseProps['items'] = validationTypesByValueType.map(type => {
        const haveContent = messagesByTick?.[type]?.length || collapseContent[type]
        
        return { key: type,
            style: itemStyle,
            destroyInactivePanel: true,
            collapsible: !haveContent ? 'disabled' : 'icon',
            label: (
                <ConfidencePicker
                    type={type}
                    selectedAnnotationId={annotationId}
                    focusedValueSnap={extractedValuesSnap}
                />
            ),
            children: 
           haveContent ? (
               <Flex gap={4} vertical>
                   {collapseContent[type]}
                   {messagesByTick[type]?.length ? (
                       <Flex gap={4}>
                           <Tooltip placement='left' title='Items generated automatically for this step'><ThunderboltFilled style={{ color: '#13C2C2' }}/></Tooltip>
                           <Typography.Text strong>Suggested Comments</Typography.Text>
                       </Flex>
                   ) : null}
                   {getMessages(messagesByTick[type])}
               </Flex>
           ) : null,
        }
    })

    const [confColor, setConfColor] = useState(COLORS.main)
    
    const [valueValidationSnap] = useCollection(query(valueValidationRef, where('extractedValueId', '==', lastSelectedAnnotation.current || 'none')));

    useAsyncEffect(async () => {
        if(!lastSelectedAnnotation.current || !valueValidationSnap?.docs.length) return

        const valueValidationData = valueValidationSnap.docs[0]?.data() as ValueValidation
        
        const confColor = getValidationValueConfColor(valueValidationData)

        setConfColor(confColor)
    }, [lastSelectedAnnotation.current,valueValidationSnap]);
    
    return (
        <Flex
            style={{
                flexGrow: 1,
                alignItems: (!annotationId) ? 'center' : 'flex-start',
                justifyContent: (!annotationId) ? 'center' : 'flex-start',
                padding: '16px 0',
            }}
        >
            {(annotationId) ? (
                <Flex vertical style={{ width: '100%' }}>
                    <span style={{ textAlign: 'center' , padding: '0 16px' }}>
                        <Typography.Title
                            style={{
                                borderBottom: `solid 3px ${confColor}`,
                                display: 'inline-flex',
                            }}
                            className={styles.valueText}
                        >
                            {value ? value : '—'}
                        </Typography.Title>
                    </span>
                    <Flex gap={4} align='center' justify='center'>
                        <Button
                            icon={<DeleteOutlined/>}
                            onClick={async () => {
                                if (!annotationId) return
                                
                                const extractedValuesSnap = await getDoc(doc(reportExtractedValuesRef, annotationId))

                                const type = extractedValuesSnap.data().type

                                if(type === 'money' ) {
                                    await updateDoc(doc(reportExtractedValuesRef, annotationId), {
                                        type: 'number',
                                    })
                                } else {
                                    await updateDoc(doc(reportExtractedValuesRef, annotationId), {
                                        rejected: true,
                                    })
                                }
                                
                                setFocusedAnnotObj(undefined)
                                setFocusedSnapIdParam(undefined)
                                lastSelectedAnnotation.current = undefined

                                deleteAnnotationByRelatedSnapId(annotationManager, annotationId)
                            }}
                            size='small'
                        />
                        <Button
                            icon={<CopyOutlined/>}
                            onClick={async () => {
                                const val = extractedValuesSnap?.data()?.originalValue
                                if(!val) return
                                navigator.clipboard.writeText(val)
                                message.success('Copied to clipboard')
                            }}
                            size='small'
                        />
                        {/*<Button*/}
                        {/*    icon={<EyeOutlined/>}*/}
                        {/*    onClick={async () => {*/}
                        {/*        annotationManager?.jumpToAnnotation(focusedAnnotationObj)*/}
                        {/*    }}*/}
                        {/*    size='small'*/}
                        {/*/>*/}
                    </Flex>
                    <Flex vertical style={{ width: '100%', marginTop: 24 }}>
                        <Collapse
                            // defaultActiveKey={items.filter(el => Boolean(el.children) || el.key === 'ma' || el.key === 'ic').map(item => item.key)}
                            defaultActiveKey={valueValidationTypes}
                            accordion={false}
                            items={items}
                            style={{
                                borderRadius: 0,
                                borderLeft: 'none',
                                borderRight: 'none',
                            }}
                        />
                    </Flex>
                </Flex>
            ) : (
                <Result
                    icon={<GatewayOutlined/>}
                    title='Please select a value'
                    extra={(
                        <Button
                            onClick={() => {
                                setIdentifyValOpen(true)
                            }}
                        >Create a New</Button>
                    )}
                />
            )}
        </Flex>
    )
}
