import React, { ReactNode, useContext, useState } from 'react'

import { useAsyncEffect } from 'ahooks';
import { Flex } from 'antd';
import { getDocs, query, where } from 'firebase/firestore';
import { StringParam, useQueryParam } from 'use-query-params';

import { PdfViewerContext2 } from '@/App.tsx';
import { StyledText } from '@/components/StyledText';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { ReviewStepComment } from '@/firestore/api/reviewStepComment.ts';
import {
    ValueValidation, ValueValidationConfidence,
    valueValidationRef,
    VAVALUE_VALIDATION_COLOR,
} from '@/firestore/api/valueValidation.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { NEED_RELUPDATE_QUERY_CONFIG, StepMessageItem } from '@/pages/ReviewPage';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId.ts';
import { LocalErrorBoundary } from '@/widgets/CriticalErrorPlaceholder';
import { ACTIVE_MONEY_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { createExtractedValueAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';

import { IndentifiedBlockItemProps } from './ExtractedBlockItem.types.ts'

interface SelectItem {
    value: ReviewIdentifiedBlockConfidence
    label: ReactNode
}

const items: SelectItem[] = [
    {
        value: 'valid',
        label: <div style={{ height: 14, width: 14, borderRadius: '20%', background: '#52c41a', flexShrink: 0 }}/>,
    },
    {
        value: 'neutral',
        label: <div style={{ height: 14, width: 14, borderRadius: '20%', background: '#ffd254', flexShrink: 0 }}/>,
    },
    {
        value: 'invalid',
        label: <div style={{ height: 14, width: 14, borderRadius: '20%', background: '#ff4d4f', flexShrink: 0 }}/>,
    },
];

export const FOCUSED_ANNOTAION_QUERY_PARAM = {
    name: 'focusedAnnotation',
    type: StringParam,
}

export const FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM = {
    name: 'extr_snap_id',
    type: StringParam,
}

export const getValidationValueConfColor = (valueValidationData: ValueValidation) => {
    const allConf = {
        ...valueValidationData.auto,
        ...valueValidationData.manual,
    }

    let conf: ValueValidationConfidence = 'notApplicable'

    const confAr = Object.entries(allConf)

    const hasValid = confAr.some(([type, confidence]) => confidence === 'valid')
    const hadInvalid = confAr.some(([type, confidence]) => confidence === 'invalid')
    const hasVerificationRequired = confAr.some(([type, confidence]) => confidence === 'verificationRequired')

    if(hasValid && !hasVerificationRequired && !hadInvalid) {
        conf = 'valid'
    } else if(hadInvalid) {
        conf = 'invalid'
    } else if(hasVerificationRequired) {
        conf = 'verificationRequired'
    }
            
    const confColor = VAVALUE_VALIDATION_COLOR[conf]

    return confColor
}

export const ExtractedBlockItem = (props: IndentifiedBlockItemProps) => {
    const { block, messages } = props
    const { annotationManager,documentViewer } = useViewerDocument()
    const { pdfInstance } = useContext(PdfViewerContext2)
    const [focusedId, setFocusedId] = useQueryParam(FOCUSED_ANNOTAION_QUERY_PARAM.name,FOCUSED_ANNOTAION_QUERY_PARAM.type)

    const [focusedSnapIdParam, setFocusedSnapIdParam] = useQueryParam(FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.name, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)
    const [,setActiveMoneyValues] = useQueryParam(ACTIVE_MONEY_VALUE_QUERY_PARAM.name, ACTIVE_MONEY_VALUE_QUERY_PARAM.type)

    const [needRelUpdate, setNeedRelUpdate] = useQueryParam(NEED_RELUPDATE_QUERY_CONFIG.name, NEED_RELUPDATE_QUERY_CONFIG.type)

    // const isFocused = focusedId?.length && (focusedId === annotation?.Id)

    const [confidenceColor, setConfidenceColor] = useState<string>()

    useAsyncEffect(async () => {
        if(!block) return

        const valueValidationSnap = await getDocs(query(valueValidationRef, where('extractedValueId', '==', block.id)))
        const valueValidationData = valueValidationSnap.docs[0]?.data() as ValueValidation

        if(valueValidationData) {
            const confColor = getValidationValueConfColor(valueValidationData)
            setConfidenceColor(confColor)
        }
    }, [block?.id]);
    
    const handleDelete = async (event) => {
        event.stopPropagation()

        try {
            // const annot = annotationManager.getAnnotationById(blockData.annotaionId || annotation.Id)
            // annotationManager.deleteAnnotations([annot])
            //
            // await updateDoc(block.ref, { rejected: true })
            //
            // if(!annot) {
            //
            //     console.error('Annotation not found for the block during deletion')
            // }
        } catch (error) {
            console.error('Error updating block', error)
            setNeedRelUpdate(true)
        }
    }

    const blockData = block.data()

    // const style = isFocused ? {
    //     // background: '#f0f0f0'
    //     boxShadow: 'inset 0 0 0 1px #0453ff',
    // } : {}

    const confidence = null

    const createAnnotations = useCreateAnnotations()
    
    return (
        <Flex vertical={true} gap={8}>
            <Flex
                key={blockData.companyId}
                justify='space-between'
                align='center'
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                    if(!annotationManager || !pdfInstance) return
                    
                    let annotation = getAnnotationBySnapId(annotationManager, block.id)

                    // FIXME: Should be only focused. Second params just for styles.
                    setActiveMoneyValues([block.id])
                    setFocusedSnapIdParam(block.id)

                    if(!annotation && createAnnotations) {
                        annotation = await createExtractedValueAnnotation({
                            pdfInstance,
                            moneyValue: block,
                            annotationManager,
                            createAnnotations,
                        })
                    }
                    
                    if(!annotation) return;
                    
                    annotationManager?.jumpToAnnotation(annotation)
                }}
            >
                <Flex
                    align='center'
                    gap={8}
                >
                    <div style={{ height: 14, width: 14, borderRadius: '20%', background: confidenceColor, flexShrink: 0 }}/>
                    <StyledText>{[].concat(blockData.originalValue).join(' ')}</StyledText>
                </Flex>
                {/*<Flex*/}
                {/*    align='center'*/}
                {/*>*/}
                {/*    <Tooltip placement='left' title='Area was identified incorrectly'>*/}
                {/*        <Button*/}
                {/*            type='link'*/}
                {/*            size='small'*/}
                {/*            onClick={handleDelete}*/}
                {/*            icon={<CloseOutlined/>}*/}
                {/*            style={{ color: '#bdb4b4' }}*/}
                {/*        />*/}
                {/*    </Tooltip>*/}
                {/*</Flex>*/}
            </Flex>
            
            <Flex vertical gap={16}>
                {messages?.map((item, index) => (
                    <LocalErrorBoundary noContent key={item.id}>
                        <StepMessageItem
                            data={item.data() as ReviewStepComment}
                            id={item.id}
                            blockCoordinates={[blockData.coordinates?.x0, blockData.coordinates?.y0, blockData.coordinates?.width, blockData.coordinates?.height]}
                        />
                    </LocalErrorBoundary>
                ))}
            </Flex>
        </Flex>
    )
}
