import React, { useContext, useEffect, useState } from 'react'

import { CheckOutlined, CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { addDoc } from '@firebase/firestore';
import { Button, Flex, Tooltip, Typography } from 'antd';
import { and, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { COLORS } from '@/constants/colors.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import {
    ValueValidation,
    ValueValidationConfidence,
    valueValidationRef,
    ValueValidationType,
} from '@/firestore/api/valueValidation.ts';
import { FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM } from '@/pages/ReviewPage';

import { ConfidencePickerProps } from './ConfidencePicker.types'

export const ConfidencePicker = (props: ConfidencePickerProps) => {
    const { type, selectedAnnotationId, focusedValueSnap } = props
    
    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()

    const [focusedSnapIdParam, setFocusedSnapIdParam] = useQueryParam(FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.name, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    const [valuesValidationSnap] = useCollection<ValueValidation>(
        query(valueValidationRef, 
            where('extractedValueId', '==', selectedAnnotationId || 'none'),
        ),
    )
    
    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['money']),
    )))

    const linksRelatedToFocusedSnap = reivewLinkObjectsSnap?.docs.find(doc => doc.data().linksGroup.some(link => link.extractedValueId === focusedSnapIdParam))

    const valueValidationSnap = valuesValidationSnap?.docs[0]

    // const [extractedValuesSnap, extractedValueLoading] = useDocument<ReportExtractedValues>(selectedAnnotationId ? doc(reportExtractedValuesRef, selectedAnnotationId) : undefined)
    
    const [localConf, setLocalConf] = useState()
    
    useEffect(() => {
        setLocalConf(undefined)
    }, [selectedAnnotationId]);

    const setAutoValidation = (type: ValueValidationType) => async (conf: ValueValidationConfidence) => {
        if (conf === localConf) return;
        
        setLocalConf(conf)

        try {
            if (!valueValidationSnap?.id) {
                const newItemData: ValueValidation = {
                    companyId: authData.company.id,
                    reportId: docId as string,
                    extractedValueId: selectedAnnotationId as string,
                    manual: {
                        [type]: conf,
                    },
                }

                await addDoc(valueValidationRef, newItemData)
            } else {
                const currentManual = valueValidationSnap.data()?.manual || {};
                await updateDoc(valueValidationSnap.ref, {
                    manual: {
                        ...currentManual,
                        [type]: conf,
                    },
                })
            }

            // FIXME: Not so good place for this logic. Better to keep in IC related component
            /**
             * Find all related link and apply same validation status for them
             */
            if(type === 'ic') {
                const relatedLinkIds = linksRelatedToFocusedSnap?.data()?.linksGroup
                    ?.filter(el => !el.rejected)
                    ?.map((link) => link.extractedValueId) || []

                const extractedValueStaps = await getDocs(query(valueValidationRef, where('extractedValueId', 'in', relatedLinkIds)))
                
                for(const valueSnap of extractedValueStaps.docs) {
                    const data = valueSnap.data()
                    // Validation block should be created for this val because back already set IC tick
                    await updateDoc(doc(valueValidationRef, valueSnap.id), {
                        manual: {
                            ...data.manual,
                            [type]: conf,
                        },
                    })
                }
            }
        } catch (error) {
            console.error('Error updating validation:', error);
            setLocalConf(undefined); // Reset local state if update fails
        }
    }
    
    const conf = localConf || valueValidationSnap?.data?.()?.manual?.[type] || valueValidationSnap?.data?.()?.auto?.[type];

    const manualActionRequired = conf === 'verificationRequired'

    return (
        <Flex
            gap={16}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
            align='center'
        >
            <Typography.Title level={3} style={{ margin: 0 }}>
                {type.toUpperCase()}
            </Typography.Title>
            {manualActionRequired && (
                <Tooltip title='Manual approval needed'>
                    <ExclamationCircleFilled style={{ fontSize: 20, color: COLORS.orange, marginTop: 2 }}/>
                </Tooltip>
            )
            }
            <Flex style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                <Flex gap={16}>
                    <Tooltip title={`Mark ${type.toUpperCase()} check result`} placement='left'>
                        <Flex gap={4}>
                            {/*<Tooltip title={`${type.toUpperCase()} check was successful for this element`} placement='left'>*/}
                            {/*    <Flex*/}
                            {/*        style={{ */}
                            {/*            height: 32, width: 32, borderRadius: '20%', background: 'yellow', flexShrink: 0,*/}
                            {/*            opacity: conf === 'verificationRequired' ? 1 : 0.3,*/}
                            {/*            display: 'flex',*/}
                            {/*            cursor: 'pointer',*/}
                            {/*        }}*/}
                            {/*        align='center'*/}
                            {/*        justify='center'*/}
                            {/*        onClick={() => setAutoValidation(type)('verificationRequired')}*/}
                            {/*    >*/}
                            {/*        {*/}
                            {/*            conf === 'valid' && <CheckOutlined style={{ color: 'white' }}/>*/}
                            {/*        }*/}
                            {/*    </Flex>*/}
                            {/*</Tooltip>*/}
                            <Flex
                                style={{ 
                                    height: 32, width: 32, borderRadius: '20%', background: COLORS.green, flexShrink: 0,
                                    opacity: conf === 'valid' ? 1 : 0.3,
                                    display: 'flex',
                                    cursor: 'pointer',
                                }}
                                align='center'
                                justify='center'
                                onClick={() => setAutoValidation(type)('valid')}
                            >
                                {
                                    conf === 'valid' && <CheckOutlined style={{ color: 'white' }}/>
                                }
                            </Flex>
                            <Flex
                                align='center'
                                justify='center'
                                onClick={() => setAutoValidation(type)('invalid')}
                                style={{ height: 32, width: 32, borderRadius: '20%', background: COLORS.red, flexShrink: 0,
                                    opacity: conf === 'invalid' ? 1 : 0.3,
                                    display: 'flex',
                                    cursor: 'pointer',
                                }}
                            >
                                {
                                    conf === 'invalid' && <CheckOutlined style={{ color: 'white' }}/>
                                }
                            </Flex>
                        </Flex>
                    </Tooltip>

                    <Tooltip title='Validation type is not applicable' placement='left'>
                        <Button
                            onClick={() => setAutoValidation(type)('notApplicable')} icon={<CloseOutlined/>}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </Flex>
    )
}
