import React, { useEffect, useState } from 'react'

import { useAsyncEffect } from 'ahooks';
import { Flex, Typography, Spin, Dropdown } from 'antd';
import { and, query, where, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { reportExtractedValuesRef } from '@/firestore/api/reportExtractedValues.ts';
import { reportPageClassificationRef } from '@/firestore/api/reportPageClassification.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import { ValueValidation, valueValidationRef } from '@/firestore/api/valueValidation.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM } from '@/pages/ReviewPage';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId';
import { ACTIVE_MONEY_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { firstLetterToUpperCase } from '@/widgets/NotesLibrary';
import { createExtractedValueAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';

import { IcValidatioinProps, LinkedItemsByPage } from './IcValidatioin.types'

export const IcValidatioin = (props: IcValidatioinProps) => {
    const [activeMoneyValues, setActiveMoneyValues] = useQueryParam(ACTIVE_MONEY_VALUE_QUERY_PARAM.name, ACTIVE_MONEY_VALUE_QUERY_PARAM.type)
    const [focusedSnapIdParam, setFocusedSnapIdParam] = useQueryParam(FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.name, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    const [scrollableAnnotationsReady, setScrollableAnnotationsReady] = useState(false)

    const { id: docId } = useParams()

    const { annotationManager, documentViewer , pdfDocument, pdfInstance } = useViewerDocument()

    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['money']),
    )))

    useEffect(() => {
        // Initially it will be equal
        setFocusedSnapIdParam(activeMoneyValues)
    }, []);

    const linksRelatedToFocusedSnap = reivewLinkObjectsSnap?.docs.find(doc => doc.data().linksGroup.some(link => link.extractedValueId === focusedSnapIdParam))

    const linkIds = linksRelatedToFocusedSnap?.data().linksGroup.map(link => link.extractedValueId)

    const [valueValidationSnap, valueValidationLoading] = useCollection<ValueValidation>(query(valueValidationRef, and(
        where('reportId', '==', docId),
        where('extractedValueId', 'in', linkIds || ['null']),
    )))

    const [activeListItem, setActiveListItem] = useState<number>();

    const [itemsList, setItemsList] = useState<LinkedItemsByPage>({});

    const [classification, classificationLoading] = useCollection(query(reportPageClassificationRef, where('reportId', '==', docId)))

    const createAnnotations = useCreateAnnotations()
    
    /**
     * Create annotations to make the money values clickable
     */
    useAsyncEffect(async () => {
        if(!annotationManager || !linksRelatedToFocusedSnap || !createAnnotations) return
        
        const annotationsList = annotationManager.getAnnotationsList()
        
        try {
            for(const link of linksRelatedToFocusedSnap.data().linksGroup) {
                const moneyValId = link.extractedValueId
                const moneyValueSnap = await getDoc(doc(reportExtractedValuesRef, moneyValId))

                let extractedValAnnot = getAnnotationBySnapId(annotationManager, moneyValueSnap.id, annotationsList)

                // not created scenario
                if (!extractedValAnnot) {
                    extractedValAnnot = await createExtractedValueAnnotation({
                        pdfInstance,
                        moneyValue: moneyValueSnap,
                        annotationsList,
                        annotationManager,
                        activeMoneyValues: activeMoneyValues || [],
                        createAnnotations,
                    })
                }
            }
        } finally {
            setScrollableAnnotationsReady(true)
        }
    }, [reivewLinkObjectsSnap, annotationManager, linksRelatedToFocusedSnap, createAnnotations]);
    
    // Reset on initial load
    useEffect(() => {
        setActiveMoneyValues([])
    }, []);

    useAsyncEffect(async () => {
        if (!focusedSnapIdParam || !reivewLinkObjectsSnap || reivewLinkObjectsSnap.empty) {
            setItemsList({})
            return
        }
        
        if(!linksRelatedToFocusedSnap) {
            setItemsList({})
            return
        }

        // // Get all linked objects for the active money value
        // const relatedExtractedValuesIds = reivewLinkObjectsSnap?.docs.map(doc => doc.data().extractedValueId) || [];
        //
        //
        // const relatedExtractedValues = await getDocs(query(reportExtractedValuesRef, where('id', 'in', relatedExtractedValuesIds)))
        //
        // Group the linked objects by page and include extracted value data
        const groupedByPage = linksRelatedToFocusedSnap.data().linksGroup
            .filter(el => !el.rejected)
            .reduce((acc, item) => {
                const pageNumber = item.page;

                if (!acc[pageNumber]) {
                    acc[pageNumber] = [];
                }

                // Find corresponding extracted value
                // const extractedValue: ReportExtractedValues = relatedExtractedValues.docs.find(
                //     ev => ev.id === linkData.extractedValueId,
                // )?.data();

                acc[pageNumber].push({
                    extractedValueId: item.extractedValueId,
                    coords: item.coords,
                    content: item.content,
                });

                return acc;
            }, {} as LinkedItemsByPage) || {};

        setItemsList(groupedByPage);
    }, [reivewLinkObjectsSnap, focusedSnapIdParam, linksRelatedToFocusedSnap]);

    const anchorItems = Object.entries(itemsList)

    if(revewLinkObjectsLoading) return <Spin size='default'/>
    if (!anchorItems.length) return null

    return (
        <Flex
            vertical
            gap={6}
            style={{
                padding: '6px 16px 6px 16px',
                background: 'white',
                borderRadius: 8,
                overflowY: 'auto',
                maxHeight: 300,
            }}
        >
            {anchorItems.map(([page, annotations]) => {
                const pageClassificationLabel = classification?.docs[0]?.data()?.labels.find((label: any) => Number(label.pageIndex) === Number(page))

                const title = pageClassificationLabel?.pageLabel ? `${firstLetterToUpperCase(pageClassificationLabel?.pageLabel.split('_').join(' ') )} - Page ${Number(page) + 1}` : `Page ${Number(page) + 1}`

                return (
                    <Flex
                        key={page}
                        vertical
                        gap={4}
                    >
                        <Typography.Text strong>{title}</Typography.Text>
                        <Flex
                            gap={4}
                            style={{ paddingLeft: 8 }}
                            wrap='wrap'
                        >
                            {annotations.map((annotation, index) => (
                                <Dropdown.Button
                                    key={`${page}-${index}`}
                                    type={activeMoneyValues?.includes(annotation.extractedValueId) ? 'primary' : 'default'}
                                    style={{ 
                                        padding: '0 6px',
                                        width: 'fit-content',
                                    }}
                                    menu={{
                                        items: [{
                                            key: 'reject',
                                            label: 'Reject',
                                            onClick: async () => {
                                                if (!reivewLinkObjectsSnap) return;
                                                
                                                // Find the document containing this annotation
                                                const docWithAnnotation = reivewLinkObjectsSnap.docs.find(doc => 
                                                    doc.data().linksGroup.some(link => 
                                                        link.extractedValueId === annotation.extractedValueId,
                                                    ),
                                                );
                                                
                                                if (!docWithAnnotation) return;
                                                
                                                // Update the linksGroup array, setting rejected=true for the matching item
                                                const updatedLinksGroup = docWithAnnotation.data().linksGroup.map(link => 
                                                    link.extractedValueId === annotation.extractedValueId
                                                        ? { ...link, rejected: true }
                                                        : link,
                                                );
                                                
                                                // Update the document in Firestore
                                                await updateDoc(doc(reviewLinkedObjectsRef, docWithAnnotation.id), {
                                                    linksGroup: updatedLinksGroup,
                                                });

                                                const snap = valueValidationSnap?.docs.find(doc => doc.data().extractedValueId === annotation.extractedValueId)

                                                if(snap) {
                                                    const data = snap.data()
                                                    // Delete IC from rejected
                                                    await updateDoc(snap.ref, {
                                                        manual: {
                                                            ...(data.manual || {}),
                                                            ic: 'notApplicable',
                                                        },
                                                    })
                                                }
                                            },
                                        }],
                                    }}
                                    disabled={!pdfInstance || revewLinkObjectsLoading || !scrollableAnnotationsReady}
                                    loading={!scrollableAnnotationsReady}
                                    /**
                                    * Since items lazy loaded, I'll create a new annotation if it doesn't exist
*/
                                    onClick={async () => {
                                        if(!pdfInstance || !annotationManager) return

                                        const moneyValId = annotation.extractedValueId

                                        const extractedValAnnot = getAnnotationBySnapId(annotationManager, moneyValId)

                                        annotationManager?.jumpToAnnotation(extractedValAnnot)
                                        setActiveMoneyValues([annotation.extractedValueId])
                                    }}
                                >
                                    {annotation.content || `Value ${index + 1}`}

                                </Dropdown.Button>
                            ))}
                        </Flex>
                    </Flex>
                )
            })}
        </Flex>
    )
}
