/**
 * Global configuratioin of the application
 */
export const CONFIG = {
    /**
     * How many pages will be filled with annotations in advance
     */
    LAZY_ANNOTATIONS_PAGE_DISTANCE: 0,

    MIXPANEL_KEY: import.meta.env.VITE_MIXPANEL_KEY,
    
    IS_PROD: process.env.NODE_ENV === 'production',
}
