import { Core } from '@pdftron/webviewer';

import AnnotationManager = Core.AnnotationManager;
import Annotation = Core.Annotations.Annotation;

import { getAnnotationBySnapId } from '@/utils/pdfViewer/getAnnotationBySnapId.ts';

export const hideBySnapId = ({
    annotationsList,
    annotationManager,
    snapId,
}: {
    annotationsList: Annotation[];
    annotationManager: AnnotationManager;
    snapId: string;
}) => {
    const annot = getAnnotationBySnapId(annotationManager, snapId, annotationsList)

    if (annot) {
        annot.NoView = true
    }
}
