import { useContext, useEffect, useState } from 'react'

import {
    BarsOutlined,
    CommentOutlined, GatewayOutlined,
} from '@ant-design/icons';
import { Flex, Menu } from 'antd';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { AnnotationContext, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM } from '@/pages/ReviewPage';

import { GuidePanelProps } from './GuidePanel.types'
import { Messages } from './Messages';
import { StepGuide } from './Steps';

export const GuidePanel = (props: GuidePanelProps) => {
    const { reviewItemData } = props
    const authData = useContext<AuthData>(AuthDataContext)
    const [focusedSnapIdParam] = useQueryParam(FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.name, FOCUSED_EXTRACTED_VALUE_SNAP_ID_QUERY_PARAM.type)

    const [navItems, setNavItems] = useState(['guidedReview']);

    const withVelt = authData.company?.data()?.features?.includes('velt')

    const annotationContextOn = authData?.company?.data()?.features?.includes('annotationContext') ?? false

    const supportedFileType = reviewItemData?.reportType !== 'other'

    useEffect(() => {
        // Show panel all the time for 'other' reports because there is not tabs
        if((focusedSnapIdParam && annotationContextOn) || !supportedFileType ) {
            setNavItems(['element'])
        }
    }, [focusedSnapIdParam]);

    const reviewInProgress = reviewItemData?.reviewStatus === 'inProgress'

    return (
        <Flex style={{ flexGrow: 1, flexDirection: 'column', overflowX: 'hidden' }}>
            {/* Hide panel button */}
            {/*<Button type='text' icon={<MenuUnfoldOutlined/>} style={{ margin: '4px 0 4px 6px' }}/>*/}
            {/*<Divider style={{ margin: 0 }}/>*/}

            <Menu
                onClick={(item) => {
                    setNavItems([item.key])
                }}
                selectedKeys={navItems}
                mode='horizontal'
                items={
                    [
                        supportedFileType ? ({
                            label: 'Steps',
                            key: 'guidedReview',
                            icon: <BarsOutlined/>,
                        }) : null,
                        (withVelt ? ({
                            label: 'Comments',
                            key: 'comments',
                            icon: <CommentOutlined/>,
                        }) : null ),
                        ((annotationContextOn && reviewInProgress) ? ({
                            label: 'Element',
                            key: 'element',
                            icon: <GatewayOutlined/>,
                        }) : null),
                    ].filter(Boolean)
                }
            />
            <Flex style={{ overflowX: 'auto', height: '100%' }}>
                {navItems.includes('guidedReview') && (
                    <StepGuide reviewItemData={reviewItemData}/>
                )}
                {navItems.includes('comments') && (
                    <Messages/>
                )}
                {navItems.includes('element') && (
                    <AnnotationContext/>
                )}
            </Flex>
        </Flex>
    )
}
