import { useContext, useEffect, useState } from 'react';

import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { useMatch } from 'react-router-dom';

import { PdfContext, PdfViewerContext2 } from '@/App.tsx';
import { ROUTES_CONFIG } from '@/constants/routes.ts';

interface UseViewerDocumentResult {
    pdfDocument: Core.Document | undefined,
    documentViewer: Core.DocumentViewer | undefined,
    annotationManager: Core.AnnotationManager | undefined,
    pdfInstance: WebViewerInstance | undefined,
}

let globalState: UseViewerDocumentResult = {
    pdfDocument: undefined,
    documentViewer: undefined,
    annotationManager: undefined,
    pdfInstance: undefined,
}

/**
 * Main utils for interacting with PDF lib
 *
 * TODO: Return promise which will be resolved when data prepared
 */
export const useViewerDocument = (): UseViewerDocumentResult => {
    const [res, setRes] = useState<UseViewerDocumentResult>(globalState)

    const { pdfInstance, setPdfInstance } = useContext<PdfContext>(PdfViewerContext2)

    const isReportPage = useMatch(ROUTES_CONFIG.REVIEW_REPORT.path)

    // Reset state when user leave the page with report
    useEffect(() => {
        if (!isReportPage || !setPdfInstance) {
            setRes({
                pdfDocument: undefined,
                documentViewer: undefined,
                annotationManager: undefined,
                pdfInstance: undefined,
            })

            setPdfInstance(null)
        }
    }, [isReportPage, setPdfInstance]);
    
    useEffect(() => {
        // Instance will be updated every time new file opened
        const isInstanceChanged = pdfInstance && res.pdfInstance !== pdfInstance

        // wait event only if stated not initialized yet
        if ((pdfInstance && !res.pdfDocument) || isInstanceChanged) {
            const documentViewer = pdfInstance.Core.documentViewer
            documentViewer.addEventListener('documentLoaded', () => {
                const document = documentViewer.getDocument()
                const annotationManager = documentViewer.getAnnotationManager()

                setRes({
                    ...res,
                    pdfDocument: document,
                    documentViewer,
                    annotationManager,
                    pdfInstance,
                })
                globalState = {
                    ...globalState,
                    pdfDocument: document,
                    documentViewer,
                    annotationManager,
                    pdfInstance,
                }
            })
        }
    }, [pdfInstance]);

    return res;
}
