import React from 'react'

import { GatewayOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { useQueryParam } from 'use-query-params';

import { IDENTIFY_VALUE_QUERY_CONFIG, magicButtonNames } from '@/widgets/MagicButtons/MagicButtons.constants.ts';

import { IdentifyValueProps } from './IdentifyValue.types'

export const IdentifyValue = (props: IdentifyValueProps) => {
    const { onClick } = props
    
    const [isOpen, setIsOpen] = useQueryParam(IDENTIFY_VALUE_QUERY_CONFIG.name, IDENTIFY_VALUE_QUERY_CONFIG.type)

    return (
        <FloatButton
            type={isOpen ? 'primary' : 'default'}
            onClick={() => {
                // setIsOpen(!isOpen);
                onClick()
            }}
            shape='square'
            icon={<GatewayOutlined/>}
            tooltip={magicButtonNames[IDENTIFY_VALUE_QUERY_CONFIG.name]}
        />
    )
}
