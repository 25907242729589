import React, { useState } from 'react'

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Flex, Result } from 'antd';
import { message } from 'antd/lib';
import { doc, updateDoc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';

import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { downloadBlobFile } from '@/firestore/utils/downloadFile.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';

import { SuccessReviewProps } from './SuccessReview.types'

export const SuccessReview = (props: SuccessReviewProps) => {
    const { id: docId } = useParams()
    const [reportSnapshot, isReportDataLoading ] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const [generatingPdf, setGeneratingPdf] = useState(false)
    const [downloaded, setDownloaded] = useState(false)

    const { annotationManager, documentViewer, pdfDocument } = useViewerDocument()
    
    const reportData = reportSnapshot?.data()
    
    const handleDownload = async () => {
        if(!annotationManager || !pdfDocument) return

        setGeneratingPdf(true)

        try {
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await pdfDocument.getFileData({ xfdfString })
  
            const arr = new Uint8Array(data);

            const blob = new Blob([ arr ], { type: 'application/pdf' });

            downloadBlobFile(blob, `${reportData?.name}_with_annotations.pdf`)

            setDownloaded(true)
        } catch (e) {
            message.error('Can\'t download the file')
        } finally {
            setGeneratingPdf(false)
        }
    }

    return (
        <Flex
            align='center' 
            vertical
            gap={8}
        >
            {/*{downloaded && (*/}
            {/*    <Alert*/}
            {/*        message='Green and yellow Tick Marks along with Links are excluded from the final report'*/}
            {/*        type='warning'*/}
            {/*        showIcon*/}
            {/*        closable*/}
            {/*    />*/}
            {/*)}*/}
            <Result
                status='success'
                title='Review successfully completed!'
                // TODO: include this message about notifications when emails ready
                // subTitle='The preparer has already been notified about the review results. The summary report is already available.'
                subTitle='Review complete! Download your summary report or generate a PDF with the annotations included.'
            />
            <Flex vertical gap={8}>
                <Button
                    type='primary'
                    loading={isReportDataLoading}
                    href={reportData?.reportType === 'regulatedFund' ? '/summary_report_funds.xlsx' : '/summary_report_cc.xlsx'}
                    icon={<DownloadOutlined/>}
                >
                    Download Summary Report
                </Button>
                <Flex gap={8}>
                    <Button
                        type='primary'
                        icon={<DownloadOutlined/>}
                        loading={generatingPdf}
                        onClick={handleDownload}
                    >
               Download Report with Annotations
                    </Button>
                </Flex>
                <Button
                    onClick={async () => {
                        if(reportSnapshot?.ref) {
                            await updateDoc(reportSnapshot.ref, { currentStep: '', reviewStatus: 'inProgress' })
                        }
                    }}
                >
               Back to Steps
                </Button>
            </Flex>
        </Flex>
    )
}
