import { message } from 'antd/lib';
import numeral from 'numeral';

type SumSelectFormat = 'format1' | 'format2' | 'format3' | 'format4' | 'format5' | 'format6'

export function identifyNumberFormat(numbers: string[]): SumSelectFormat | null {
    // Define regex patterns for different number formats
    const format1 = /^-?\d{1,3}(\.\d{3})*(,\d{1,2})?$/; // e.g., -64.684.940,16 or 64.684.940,16
    const format2 = /^-?\d{1,3}(,\d{3})*(\.\d{1,2})?$/; // e.g., -640,760.9491 or 640,760.9491
    const format3 = /^-?\d{1,3}( \d{3})*(,\d{1,2})?$/; // e.g., 8 001 888,12 or -8 001 888,12
    const format4 = /^-?\d{1,3}( \d{3})*(\.\d{1,2})?$/; // e.g., 8 001 888.12 or -8 001 888.12
    const format5 = /^-?\d+(\.\d{1,2})?$/; // e.g., 8000.12 or -8000.12
    const format6 = /^-?\d+(,\d{1,2})?$/; // e.g., 8000,12 or -8000,12

    const res = {
        format1: 0,
        format2: 0,
        format3: 0,
        format4: 0,
        format5: 0,
        format6: 0,
    }

    numbers.forEach(num => {
        if (format1.test(num)) {
            res.format1 = (res.format1 || 0) + 1
        } else if (format2.test(num)) {
            res.format2 = (res.format2 || 0) + 1
        } else if (format3.test(num)) {
            res.format3 = (res.format3 || 0) + 1
        } else if (format4.test(num)) {
            res.format4 = (res.format4 || 0) + 1
        } else if (format5.test(num)) {
            res.format5 = (res.format5 || 0) + 1
        } else if (format6.test(num)) {
            res.format6 = (res.format6 || 0) + 1
        }
    });
    
    // If all zeros
    if (Object.values(res).every(val => val === 0)) {
        console.error('SumSel. No format found', numbers)
        return null
    }

    // Find dominant format
    const format = Object.entries(res).reduce((acc, [key, val]) => {
        if (val > acc[1]) {
            return [key, val]
        }
        return acc
    }, ['format1', 0])
    
    return format[0] as SumSelectFormat
}

export function convertToDecimalReady(num: string, format: SumSelectFormat | null): string {
    let res = undefined

    switch (format) {
        case 'format2':
            res = num.replace(/,/g, '');
            break;
        case 'format1':
            // Remove all dots and replace the last comma with a dot
            return num.replace(/\./g, '').replace(/,(\d+)$/, '.$1');
            break
        case 'format3':
            res = num.replace(/ /g, '');
            break;
        case 'format4':
            res = num.replace(/ /g, '').replace(/,/, '.');
            break
        case 'format5':
            res = num;
            break
        case 'format6':
            res = num.replace(/,/, '.');
            break
        default:
            console.error('Unknown format', format, num)
            return null
    }

    return res
}

export const replaceBrackets = (numbers: string[]) => numbers.map(num => {
    if (/^\([\d\.\,\s]+\)$/.test(num)) {
        return '-' + num.replace(/[()]/g, '');
    }
    return num;
});

export function calculateMaxDecimals(numbers: string[]): number {
    const numbersNorm = replaceBrackets(numbers);
    const onlyNumberSymbols = numbersNorm.map(el => el.replace(/[^0-9,.]/g, ''))
    const format = identifyNumberFormat(onlyNumberSymbols);
    
    return onlyNumberSymbols.reduce((acc, el) => {
        const norm = convertToDecimalReady(el, format)
        const decimals = norm?.split('.')[1]?.length || 0;

        return decimals > acc ? decimals : acc;
    }, 0);
}

export const normalizeTextWithNumbers = (textForSum: string): {numbers: number[], maxDecimals: number} => {
    const lines = textForSum.split('\n');
    
    const filtered = lines
        .map(word => word.trim().replace(/[^\d.,()\-]/g, ''))
    // Normalize negative numbers with brackets
        .map(word => {
            // Test if it's a negative number with brackets format
            if (/^\([\d\.\,]+\)$/.test(word)) {
                return '-' + word.replace(/[()]/g, '');
            }
            return word;
        })
        .filter(word => word !== '')

    const format =
        identifyNumberFormat(filtered);

    const extracted = filtered
    // Normalize numbers to the same format
        .map(num => convertToDecimalReady(num, format))
    
    const strings = extracted
        .filter(num => num !== null) as string[]

    // If some values where filtered out because of unrecognized format
    if(extracted.length !== strings.length) {
        console.error('Some numbers were not normalized', extracted, 'norm', strings)
        message.warning('Some numbers were not recoginzed.')
    }

    const maxDecimals = calculateMaxDecimals(strings)
    const numbers = strings.map(el => Number(numeral(el).format('0.00000')))

    return {
        numbers,
        maxDecimals,
    }
}
