import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export interface ReportTableObjects {
    companyId: string;
    reportId: string;
    boundingRegions: {
        x: number;
        y: number;
        width: number;
        height: number;
        page: number
    }[]
    rowConfigs: {
        bold: boolean;
        label: string | null;
        separator: number;
        sumTotal: string | null;
        total?: boolean
    }[]
}

export const REPORT_TABLE_OBJECTS_COLLECTION = 'reportTableObjects'

export const reportTableObjectsRef = collection(db, REPORT_TABLE_OBJECTS_COLLECTION)
