import React from 'react'

import { MessageOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface NativeCommentButtonProps {
    onClick?: () => void;
}

export const COMMENT_TOOL_QUERY_CONFIG = {
    name: 'commentTool',
    type: BooleanParam,
}

export const NativeCommentButton = (props: NativeCommentButtonProps) => {
    const { onClick } = props
    const [isOpen, setIsOpen] = useQueryParam(COMMENT_TOOL_QUERY_CONFIG.name, COMMENT_TOOL_QUERY_CONFIG.type)
    
    return (
        <FloatButton
            type={isOpen ? 'primary' : 'default'}
            onClick={() => onClick?.()}
            shape='square'
            icon={<MessageOutlined/>}
        />
    )
}
