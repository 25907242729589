import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import 'pdfjs-dist/web/pdf_viewer.css';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
// import { useVeltClient } from '@veltdev/react';
import { and, doc, onSnapshot, query, where } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam, BooleanParam } from 'use-query-params';

import { firebaseDownloadUrl } from '@/api/utils/firebase/firebaseDownloadUrl.ts';
import { PdfContext, PdfViewerContext2 } from '@/App.tsx';
import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { QUERY_PARAMS_CONFIG } from '@/config/queryParams.ts';
import { CONFIG } from '@/config.ts';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey';
import { InputFile, inputFilesRef } from '@/firestore/api/inputFiles.ts';
import { reportAnnotationRef } from '@/firestore/api/reportAnnotation.ts';
import { ReportExtractedValues, reportExtractedValuesRef } from '@/firestore/api/reportExtractedValues.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewLinkedObjects, reviewLinkedObjectsRef } from '@/firestore/api/reviewLinkedObjects.ts';
import {
    ValueValidation,
    ValueValidationConfidence,
    valueValidationRef, ValueValidationType,
    valueValidationTypes,
    VAVALUE_VALIDATION_COLOR,
} from '@/firestore/api/valueValidation.ts';
import { useCurrentPage } from '@/hooks/useCurrentPage.ts';
import { useIsScrolling } from '@/hooks/useIsScrolling.ts';
import { usePerformanceTrace } from '@/hooks/usePerformanceTrace';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { calculateRectIntersection } from '@/utils/calculateRectIntersection.ts';
import { createRange } from '@/utils/createRange.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';
import { hideBySnapId } from '@/utils/pdfViewer/hideBySnapId.ts';
import { AI_CHAT_QUERY_CONFIG } from '@/widgets/MagicButtons/MagicButtons.constants.ts';
import { ACTIVE_MONEY_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { useAnnotStylesMofier, useApplyAnnotations, useTableDebug } from '@/widgets/PdfViewer2/PdfViewer2.hooks.ts';
import { AnnotationVariant, PdfViewer2Props } from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { createAnnotation, createExtractedValueAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';
import { useAnnotationsListener } from '@/widgets/PdfViewer2/useAnnotationsListener.ts';
import { useToolsListener } from '@/widgets/PdfViewer2/useToolsListener.ts';

import { useAsyncEffect } from 'ahooks';
import { Flex, Spin } from 'antd';

// Rename to annot type
export const enum CustomToolNames {
    TickMark = 'TickMark',
    SumSelect = 'SumSelect',
    CrossLink = 'CrossLink',
    ValueIdentify = 'ValueIdentify',
}

const libVersion = '10111';
const VIEWER_LIB_STATIC_URL = `${window.location.protocol}//${window.location.host}/pdf-viewer/${libVersion}`;

interface StoredFile {
    id: string;
    blob: Blob;
    timestamp: number;
}

export const getConf = (valueValidation: ValueValidation, type: ValueValidationType): ValueValidationConfidence => valueValidation['manual']?.[type] || valueValidation['auto']?.[type]

/**
 * FIXME:
 * - Limit right click elements
 *
 * @todo: Wrap with 'Sentry.profiler' for performance monitoring
 *
 * LINKS:
 * 3 popup types: https://docs.apryse.com/documentation/web/guides/customizing-popup/
 */
export const PdfViewer2 = (props: PdfViewer2Props) => {
    const { fileUrl } = props;

    const { id: docId } = useParams()

    const { annotationManager, documentViewer, pdfDocument } = useViewerDocument()

    const authData = useContext<AuthData>(AuthDataContext)

    const [activeMoneyValues] = useQueryParam(ACTIVE_MONEY_VALUE_QUERY_PARAM.name, ACTIVE_MONEY_VALUE_QUERY_PARAM.type)

    const [reivewLinkObjectsSnap, revewLinkObjectsLoading] = useCollection<ReviewLinkedObjects>(query(reviewLinkedObjectsRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['ecdfNoteLink']),
    )))

    const [valueValidationSnap, valueValidationLoading] = useCollection<ValueValidation>(valueValidationRef, and(
        where('reportId', '==', docId),
    ))

    const [tab] = useQueryParam(QUERY_PARAMS_CONFIG.TAB.key, QUERY_PARAMS_CONFIG.TAB.type);

    const page = useCurrentPage()

    useAnnotStylesMofier()

    const [reportExtractedValuesSnap, reportExtractedValuesLoading] = useCollection<ReportExtractedValues>(query(reportExtractedValuesRef, and(
        where('reportId', '==', docId),
        where('type', 'in', ['money', 'manual', 'text', 'pageNumber', 'TOC', 'empty']),
    )))

    useEffect(() => {

        setApplyAnnotCursor(0)
    }, [page, reportExtractedValuesSnap, valueValidationSnap])

    const [reportSnapshot, reportSnapshotLoading] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const reportData = reportSnapshot?.data()
    const reviewInProgress = !(reportData?.reviewStatus !== 'inProgress')

    // Triggers to reset cursor
    useEffect(() => {
        if (page) {
            setApplyAnnotCursor(0)
        }
    }, [page, tab, reviewInProgress])

    const instansInited = useRef(false);
    const viewer = useRef(null);
    const prefPagesApplied = useRef<number[]>([]);
    const { pdfInstance, setPdfInstance } = useContext<PdfContext>(PdfViewerContext2)

    // const { client: veltClient } = useVeltClient()

    useToolsListener()
    useAnnotationsListener({
        reportSnapshot,
    })

    const [annotationsReady, setAnnotationsReady] = useState(false)

    useAsyncEffect(async () => {
        if (!documentViewer) return

        // Supposedly it's the beset time when we can start draw annotatations
        documentViewer.getAnnotationsLoadedPromise().then(() => {
            setAnnotationsReady(true)
        }).catch((e) => {
            console.error('Error loading annotations', e)
        })
    }, [documentViewer])

    const addCustomTools = useCallback((instance: WebViewerInstance, docViewer: Core.DocumentViewer) => {

    }, [])

    const Feature = pdfInstance?.UI.Feature;

    useApplyAnnotations({})

    const annotationContextOn = authData?.company?.data()?.features?.includes('annotationContext') ?? false

    useTableDebug()

    // FIXME: Implement sentry logging on failed FB request
    useEffect(() => {
        // Логирование за��росов
        onSnapshot(reportReviewRef, (snapshot) => {
            //
            //
        }, (error) => {
            console.error('Error fetching data:', error);
            //
        });

        // Логирование запросов
        onSnapshot(reportAnnotationRef, (snapshot) => {
            //
            //
        }, (error) => {
            console.error('Error fetching data:', error);
            //
        });
    }, []);

    /**
     * Apply links ecdf-notes
     */
    useAsyncEffect(async () => {
        if (!reivewLinkObjectsSnap || !annotationManager || revewLinkObjectsLoading || !pdfInstance || !annotationsReady || reportExtractedValuesLoading) return
        if (reportData?.reviewStatus !== 'inProgress') return

        const filtered = reivewLinkObjectsSnap.docs

        const annotationsList = annotationManager.getAnnotationsList()

        for (const linkObject of filtered) {
            const linkData = linkObject.data()

            const groupUniqueKey = linkObject.id

            for (let i = 0; i < linkData.linksGroup.length; i++) {
                const linkItem = linkData.linksGroup[i]

                const { coords, page, content, label } = linkItem

                if (!coords) {
                    console.error('No coords for link', linkItem)
                    continue
                }

                const normalizedValue =
                    (linkData.type === 'money')
                        ? reportExtractedValuesSnap?.docs.find((doc) => doc.ref.id === linkItem.extractedValueId)?.data().normalizedValue ?? null
                        : null

                const annotation = await createAnnotation({
                    createAnnotations,
                    annotationsList,
                    padding: 1,
                    annotationManager,
                    pdfInstance: pdfInstance,
                    type: 'link',
                    pageIndex: page,
                    coordinates: coords,
                    showInNotesPanel: false,
                    toolName: CustomToolNames.CrossLink,
                    snapshotRerenderKey: reviewInProgress ? 'true' : 'false',
                    relatedSnapshotId: linkObject.id + '_' + i,
                    hidden: !reviewInProgress,
                    annotationVariant: normalizedValue && activeMoneyValues?.includes(normalizedValue.toString())
                        ? AnnotationVariant.moneyValueActive
                        : undefined,
                    reply: linkData.type === 'money' ? 'Same values found' : 'eCDF - Note',
                    excludeFromSummary: true,
                    customData: {
                        linkGroup: groupUniqueKey,
                        tickConfidence: 'link', // FIXME: Rename later to style/type. Related to Identified blocks type.
                        linkLabel: label,
                        ignoreStepKey: 'true',
                        toolName: CustomToolNames.CrossLink,
                        relatedLinkSnapshotId: linkObject.id,
                        linkIndex: linkData.linksGroup.indexOf(linkItem).toString(),
                        crossLInkType: linkData.type,
                        normalizedValue: normalizedValue,
                    },
                })
            }
        }
    }, [annotationManager, revewLinkObjectsLoading, pdfInstance, reportData?.currentStep, annotationsReady, reportExtractedValuesLoading, reviewInProgress]);

    // Hide annotations on zoom for optimization
    // useEffect(() => {
    //     if(!pdfInstance) return
    //
    //     let isZooming = false;
    //     let zoomTimeout;
    //
    //     pdfInstance.Core.documentViewer.addEventListener('zoomUpdated', function(zoomLevel) {
    //         if (!isZooming) {
    //             isZooming = true;
    //             hideAnnotations();
    //         }
    //
    //         clearTimeout(zoomTimeout);
    //         zoomTimeout = setTimeout(() => {
    //             isZooming = false;
    //             showAnnotations();
    //         }, 200); // Adjust delay as needed
    //     });
    //
    //     function hideAnnotations() {
    //         pdfInstance.Core.annotationManager.hideAnnotations(
    //             pdfInstance.Core.annotationManager.getAnnotationsList().filter(ann => ann.getPageNumber() === page),
    //         );
    //     }
    //
    //     function showAnnotations() {
    //         pdfInstance.Core.annotationManager.showAnnotations(
    //             pdfInstance.Core.annotationManager.getAnnotationsList().filter(ann => ann.getPageNumber() === page),
    //         );
    //     }
    // }, [pdfInstance]);

    useEffect(() => {
        if(!documentViewer) return

        // Performance optimization
        documentViewer.disableAutomaticLinking();
    }, [documentViewer]);

    const createAnnotationsLIst = useRef<Core.Annotations.Annotation[]>([])

    const isScrolling = useIsScrolling(pdfInstance, 10)

    // FIXME: To false
    const initialValuesRenderDone = useRef<boolean>(true)

    // Version for Downloading
    const [allAnnotationsSummaryPrepared, setAllAnnotationsSummaryPrepared] = useState(false)

    const [, setAnnotatonsReadyForSummary] = useQueryParam(QUERY_PARAMS_CONFIG.ANNOTATIONS_READY_FOR_SUMMARY.key, QUERY_PARAMS_CONFIG.ANNOTATIONS_READY_FOR_SUMMARY.type)

    // Summary annotations preparation in progress
    useEffect(() => {
        if(!reviewInProgress && !allAnnotationsSummaryPrepared) {
            setAnnotatonsReadyForSummary(false)
        } else {
            setAnnotatonsReadyForSummary(true)
        }
    }, [allAnnotationsSummaryPrepared, reviewInProgress]);

    useEffect(() => {
        if(reviewInProgress) {
            // Reset if we get back to review
            setAnnotatonsReadyForSummary(false)
        }
    }, [reviewInProgress]);

    const [applyAnnotCursor, setApplyAnnotCursor] = useState<number>(0)

    const createAnnotations = useCreateAnnotations()

    const [lazyLoadingInProgress, setLazyLoadingInProgress] = useState(false)

    /**
     * Lazy apply of extracted 'Money' annotations
     */
    useAsyncEffect(async () => {
        if (
            !createAnnotations ||
            !reportExtractedValuesSnap ||
            !annotationManager ||
            reportExtractedValuesLoading ||
            !annotationsReady ||
            !pdfInstance ||
            !documentViewer ||
            valueValidationLoading ||
            // Skip annotations rendering if scrolling
            isScrolling
        ) return

        // Apply all annotations only once
        if(allAnnotationsSummaryPrepared === true && !reviewInProgress) {
            return
        }

        setLazyLoadingInProgress(true)

        // Show hidden annotations
        pdfInstance.Core.annotationManager.showAnnotations(
            pdfInstance.Core.annotationManager.getAnnotationsList().filter(ann => ann.getPageNumber() === page),
        );

        const applyForPages =
            reviewInProgress
                ? createRange(page, CONFIG.LAZY_ANNOTATIONS_PAGE_DISTANCE, 1, documentViewer.getPageCount())
                // Apply for all pages to make sure all annotations are downloadable
                : createRange(1, documentViewer.getPageCount(), 1, documentViewer.getPageCount())

        const step = reviewInProgress ? 10 : 200

        const ecdfLInkGroups =
            reivewLinkObjectsSnap?.docs.map(doc => doc.data()).filter(el => el.type === 'ecdfNoteLink')
                .map(el => el.linksGroup).flat()

        const moneyValues = reportExtractedValuesSnap.docs
            // Only pages in nearest range
            .filter((el) => {
                return applyForPages.includes(el.data().page + 1)
            })
            .filter(el => (el.data().rejected !== true))
        // Exlude values which have link with notes sections. It's for sure invalid money values
            .filter(el => {
                // Skip if no coordinates
                if (!el.data().coordinates) return true;

                // Check intersection with each ecdfLink group item
                return !ecdfLInkGroups.some(linkItem => {
                    if (!linkItem.coords) return false;

                    const moneyValueRect = {
                        ...el.data().coordinates,
                        page: el.data().page,
                    }

                    const linkRect = {
                        x0: linkItem.coords[0],
                        y0: linkItem.coords[1],
                        width: linkItem.coords[2],
                        height: linkItem.coords[3],
                        page: linkItem.page,
                    };

                    const isIntersecting = calculateRectIntersection(moneyValueRect, linkRect, 10);

                    return isIntersecting;
                });
            })

        const slicedMoneyValues = moneyValues.slice(applyAnnotCursor, applyAnnotCursor + step)

        const annotationsList = annotationManager.getAnnotationsList()

        for (const moneyValue of slicedMoneyValues) {
            const { coords, page: annotPage, originalValue, normalizedValue, coordinates } = moneyValue.data()

            if(reviewInProgress) {
                const annotation = createExtractedValueAnnotation({
                    doNotCreate: true,
                    pdfInstance,
                    moneyValue,
                    annotationsList,
                    annotationManager,
                    activeMoneyValues,
                    createAnnotations,
                })

                if(!annotation) continue
                createAnnotationsLIst.current.push(annotation)
            } else {
                hideBySnapId({
                    annotationsList,
                    annotationManager,
                    snapId: moneyValue.id,
                })
            }

            const relatedValueValidation = valueValidationSnap?.docs.find((doc) => {
                return doc.data().extractedValueId === moneyValue.id
            })

            if (relatedValueValidation) {
                const updateAnnotationTicks = (valueValidationS) => {
                    const valueValidation = valueValidationS.data()

                    let indexStart = 0
                    const updateAnnotationTicks: ({
                        text: string,
                        color: string,
                        indexStart
                    } | null)[] = valueValidationTypes.map((type, index) => {
                        const conf = getConf(valueValidation, type)

                        const displayConfList: ValueValidationConfidence[] = ['valid', 'invalid', 'verificationRequired']

                        const displayConf = displayConfList.includes(conf)

                        if (!conf || !displayConf) return null

                        const color = VAVALUE_VALIDATION_COLOR[conf]

                        const text = type.toUpperCase()

                        const res = {
                            indexStart,
                            color,
                            text,
                        }

                        // 1 - space
                        indexStart += text.length + 1

                        return res
                    }).filter(Boolean)

                    const textContent = updateAnnotationTicks.map(el => el.text).join(' ')

                    const uniqueId = moneyValue.id + '_' + textContent + updateAnnotationTicks.map(el => el?.color).join(' ')

                    const annotation = createAnnotation({
                        createAnnotations,
                        doNotCreate: true,
                        annotationsList,
                        annotationManager,
                        pdfInstance: pdfInstance,
                        annotationType: 'FreeText',
                        pageIndex: annotPage,
                        // Need only two because of autosize
                        // 24, 4 - width/height of the annot
                        coordinates: [coordinates?.x0, coordinates?.y0 + coordinates?.height + 1, 24, 4],
                        showInNotesPanel: false,
                        annotationVariant: AnnotationVariant.valueConfidence,
                        fondSize: '5pt',
                        bold: true,
                        relatedSnapshotId: valueValidationS.id,
                        snapshotRerenderKey: uniqueId,
                        textContent: textContent,
                        readOnly: true,
                        richTextStyle: updateAnnotationTicks.reduce((acc, el) => {
                            acc[el.indexStart] = {
                                'color': el.color,
                                'font-weight': 'bold',
                            }
                            return acc
                        }, {}),
                        customData: {
                            skipEventHandlers: 'true',
                            annotationVariant: AnnotationVariant.valueConfidence,
                        },
                    })

                    if(annotation) {
                        createAnnotationsLIst.current.push(annotation)
                    }
                }

                updateAnnotationTicks(relatedValueValidation)
            }
        }

        const pagesToClean = prefPagesApplied.current.filter(page => !applyForPages.includes(page))

        if(pagesToClean.length) {
            // Only delete annotations for pages that aren't in the current applyForPages
            const annotToDelete = annotationsList.filter(annot => {
                const isMoneyVal = annot.getCustomData(CustomDataKey.annotationVariant) === AnnotationVariant.moneyValue
                return pagesToClean.includes(annot.PageNumber) && isMoneyVal
            })

            if (annotToDelete.length && reviewInProgress) {
                annotationManager.hideAnnotations(annotToDelete);
            }
        }

        prefPagesApplied.current = applyForPages

        if(createAnnotationsLIst.current.length) {
            const list = (await Promise.all(createAnnotationsLIst.current))
                .filter(Boolean)
            createAnnotations(list)
            createAnnotationsLIst.current = []
        }

        if(!initialValuesRenderDone.current && reviewInProgress) {
            // hide non active pages annotations
            annotationManager.hideAnnotations(
                annotationManager.getAnnotationsList().filter(ann => !applyForPages.includes(ann.getPageNumber()) && ann.getPageNumber() !== page),
            );
            initialValuesRenderDone.current = true
        }

        if(applyAnnotCursor < moneyValues.length) {
            setLazyLoadingInProgress(true)
            setTimeout(() => {
                setApplyAnnotCursor(val => val + step)
            }, 10)
        } else {
            setLazyLoadingInProgress(false)

            // Apply all annotations only once for summary
            if(!reviewInProgress) {
                setAllAnnotationsSummaryPrepared(true)
            }
        }
    }, [
        createAnnotations,
        annotationsReady,
        reportExtractedValuesLoading,
        reportExtractedValuesSnap,
        annotationManager,
        pdfInstance,
        page,
        documentViewer,
        valueValidationLoading,
        valueValidationSnap,
        applyAnnotCursor,
        isScrolling,
        tab,
        reviewInProgress,
    ]);

    // Annot hover cursor change
    // useEffect(() => {
    //     if(!documentViewer || !pdfInstance) return
    //     documentViewer.addEventListener('toolModeUpdated', (tool) => {
    //         //
    //         if (tool instanceof pdfInstance.Core.Tools.PolygonCreateTool) {
    //             pdfInstance.Core.Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = false;
    //         } else {
    //             pdfInstance.Core.Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = true;
    //         }
    //     });
    // }, [documentViewer]);

    // FIt to width for small screen
    useEffect(() => {
        if (!pdfInstance) return

        // 15 inch Mac screen: 1512
        if (window.innerWidth < 1600) {
            pdfInstance.UI.setFitMode(pdfInstance.UI.FitMode.FitWidth)
        }
    }, [pdfInstance]);

    // const initVeltComments = useCallback((instance: WebViewerInstance, annotManager: Core.AnnotationManager) => {
    //     instance.UI.annotationPopup.add(
    //         {
    //             type: 'actionButton',
    //             title: 'Comment',
    //             img: 'icon-tool-comment-line',
    //             onClick: () => {
    //                 const annots = annotManager.getSelectedAnnotations();
    //                 if (!annots || !annots[0]) {
    //                     return;
    //                 }
    //                 const [annot] = annots;
    //                 const id = annot.Id
    //
    //                 annotManager.redrawAnnotation(annot);
    //
    //                 const locationName = id
    //                 const locationId = id
    //
    //                 const locationConfig = {
    //                     id: locationId,
    //                     locationName,
    //                 }
    //
    //                 veltClient.setLocation(locationConfig)
    //                 veltClient.setLocation({
    //                     id: 'step-1',
    //                     locationName: 'Step 1',
    //                 }, true)
    //
    //                 // Activate filter in Sidebar by account
    //                 const filters = {
    //                     location: [
    //                         { id: locationId },
    //                     ],
    //                 }
    //                 const commentElement = veltClient.getCommentElement()
    //                 commentElement.setCommentSidebarFilters(filters)
    //             },
    //         },
    //     )
    // }, [veltClient])

    // Reset Velt filter on pageLoading
    useEffect(() => {
        if ( !pdfInstance || !annotationManager) return

        const filters = {
            location: [],
        }
        // const commentElement = veltClient.getCommentElement()
        // commentElement.setCommentSidebarFilters(filters)

        // FIXME: Return later
        // initVeltComments(pdfInstance, annotationManager)
    }, [pdfInstance, annotationManager]);

    const [tabLoading ,setTabLoadingParam] = useQueryParam('tabLoading', BooleanParam);

    // Add query for inputFiles
    const [inputFileSnap, inputFileLoading] = useCollection<InputFile>(
        query(inputFilesRef, where(
            'reportId', '==', docId,
        )),
    );

    useAsyncEffect(async () => {
        await cleanUpOldFiles();
    }, [])

    /**
     * Preload all files for the review process
     */
    const filePromisesRef = useRef<Record<string, Promise<Blob | null>>>({});

    // First useEffect to initialize loading of all files
    useEffect(() => {
        if (!pdfInstance || inputFileLoading || reportSnapshotLoading) return;

        const loadDocument = async (fileId: string, bucketPath: string) => {
            let blob = null;
            try {
                const fieItem = await getFileFromIndexedDB(fileId)
                blob = fieItem?.blob
            } catch (e) {
                console.error('Error getting file from indexedDB', e);
            }

            if (!blob) {
                const url = await firebaseDownloadUrl(bucketPath);
                const response = await fetch(url);
                blob = await response.blob();
                try {
                    await saveFileToIndexedDB(fileId, blob);
                } catch (e) {
                    console.error('Error saving file to indexedDB', e);
                }
            }

            return blob;
        };

        if (inputFileSnap?.docs.length) {
            for (const fileSnap of inputFileSnap.docs) {
                const file = fileSnap.data();
                const bucketPath = file.storagePath;
                const fileId = fileSnap.id;

                // Store the promise for loading the main file
                filePromisesRef.current[fileId] = loadDocument(fileId, bucketPath);
            }
        }
    }, [pdfInstance, inputFileSnap, inputFileLoading, reportSnapshot]);

    // Add PDF loading tracing
    const { putMetric, putAttribute, stopTrace } = usePerformanceTrace('pdf_loading', {
        attributes: {
            docId: docId || 'unknown',
        },
        startImmediately: !!docId, // Start only when docId is available
        active: !!docId, // Start only when docId is available
    });

    // Second useEffect to load the file into the PDF Viewer
    useAsyncEffect(async () => {
        if (!pdfInstance || !inputFileSnap || !tab) return;

        const filePromise = filePromisesRef.current[tab];

        if (filePromise) {
            try {
                setTabLoadingParam(true);
                const blob = await filePromise;
                if(blob) {
                    await pdfInstance.UI.loadDocument(blob, {
                        extension: 'pdf',
                    });
                } else {
                    console.error('Error loading file from indexedDB');
                    putAttribute('error', 'Failed to load from IndexedDB');
                }
            } finally {
                setTabLoadingParam(false);
                stopTrace()
            }
        } else {
            pdfInstance.UI.loadDocument(fileUrl, {
                extension: 'pdf',
            });
        }
    }, [pdfInstance, inputFileSnap, inputFileLoading, tab]);

    const [, setIsAiChatOpen] = useQueryParam(AI_CHAT_QUERY_CONFIG.name, AI_CHAT_QUERY_CONFIG.type)

    useEffect(() => {
        if (instansInited.current || !fileUrl) {
            return;
        }
        instansInited.current = true

        WebViewer(
            {
                path: VIEWER_LIB_STATIC_URL,
                licenseKey: 'NEXLY TECH CORP:OEM:Nexly::B+:AMS(20251204):465716021FC78AD0533353184F714F262292BC7DA3BF690187A7C96D4E21BEF5C7',
                // Allows to modify annotations from other users
                isAdminUser: true,
                extension: ['pdf'],
            },
            viewer.current,
        ).then((instance: WebViewerInstance) => {
            const docViewer = instance.Core.documentViewer as Core.DocumentViewer;

            instance.Core.Annotations.setCustomDrawHandler(instance.Core.Annotations.RectangleAnnotation, function(ctx, pageMatrix, rotation, options) {
                // const similarAnnotations = pageMatrix.getPage().getAnnotations().filter(a => a instanceof instance.Core.Annotations.RectangleAnnotation);
                //
                // ctx.beginPath();
                // for (const annotation of similarAnnotations) {
                //     ctx.rect(annotation.X, annotation.Y, annotation.Width, annotation.Height);
                // }
                // ctx.fill();
                // ctx.stroke();

                options.originalDraw(ctx, pageMatrix, rotation);
            }, {
                generateAppearance: false,
                canvasMultiplier: 0,
            });

            // Keeps only main tools active on the panel
            instance.UI.disableElements([
                'toolbarGroup-Annotate',
                'toolbarGroup-Insert',
                'toolbarGroup-Shapes',
                'toolbarGroup-Measure',
                'toolbarGroup-Forms',
                'toolbarGroup-Edit',
                'toolbarGroup-FillAndSign',
                'toolbarGroup-View',
                'toolsHeader',
                'toolbarGroup-Edit',
                'tools-header',
                'toolbarGroup-Redact',
                'toolbarGroup-EditText',
                // Signature
                'CustomSave',
                'toolbarGroup-FillAndSign',
                'annotationClearSignatureButton',
            ]);

            // Turn off comments
            instance.UI.disableElements([
                // FIXME: Uncomment and switch to Velt comments
                // 'annotationCommentButton',
                // 'notesPanelButton',
                // 'notesPanel',
                // 'toggleNotesButton',
                'redactButton',
            ]);

            // Note tooltip
            instance.UI.disableElements([
                'linkButton',
                'annotationStyleEditButton',
                'annotationGroupBu-tton',
            ]);

            instance.UI.textPopup.add(
                {
                    type: 'actionButton',
                    title: 'Ask Nexly AI',
                    img: '/ai.svg',
                    onClick: () => {
                        setIsAiChatOpen(true)
                    },
                },
            )
            
            addCustomTools(instance, docViewer)

            setPdfInstance(instance)
        })
            .catch((error) => {
                console.error('Error loading WebViewer:', error);

            })
    }, [fileUrl]);

    useEffect(() => {
        if(!pdfInstance || !Feature) return
        //     // FIXME: Enable later
        //     //. For now it activates during usage of AI chat
        //     const disbleKeys = [
        //         pdfInstance.UI.hotkeys.Keys.SPACE,
        //         pdfInstance.UI.hotkeys.Keys.ESCAPE,
        //         pdfInstance.UI.hotkeys.Keys.P,
        //         pdfInstance.UI.hotkeys.Keys.A,
        //         pdfInstance.UI.hotkeys.Keys.C,
        //         pdfInstance.UI.hotkeys.Keys.E,
        //         pdfInstance.UI.hotkeys.Keys.F,
        //         pdfInstance.UI.hotkeys.Keys.I,
        //         pdfInstance.UI.hotkeys.Keys.L,
        //         pdfInstance.UI.hotkeys.Keys.N,
        //         pdfInstance.UI.hotkeys.Keys.O,
        //         pdfInstance.UI.hotkeys.Keys.R,
        //         pdfInstance.UI.hotkeys.Keys.Q,
        //         pdfInstance.UI.hotkeys.Keys.T,
        //         pdfInstance.UI.hotkeys.Keys.S,
        //         pdfInstance.UI.hotkeys.Keys.G,
        //         pdfInstance.UI.hotkeys.Keys.H,
        //         pdfInstance.UI.hotkeys.Keys.K,
        //         pdfInstance.UI.hotkeys.Keys.U,
        //         pdfInstance.UI.hotkeys.Keys.X,
        //         pdfInstance.UI.hotkeys.Keys.S,
        //     ]
        //
        //     for(const key of disbleKeys) {
        //         pdfInstance.UI.hotkeys.off(
        //             key,
        //         )
        //     }
        //
        //     // Disable all hotkeys
        //     pdfInstance.UI.hotkeys.off()
        //     // In some reasyn S keyw wasn't turned off by .off()
        //     pdfInstance.UI.hotkeys.on(pdfInstance.UI.hotkeys.Keys.S, () => {
        //         console.log('s pressed')
        //         debugger
        //     })
        //
        //     pdfInstance.UI.hotkeys.on('s', () => {
        //         console.log('s pressed')
        //         debugger
        //     })
        //
        //     pdfInstance.UI.disableTools([
        //         pdfInstance.Core.Tools.ToolNames.SIGNATURE,
        //     ]);
        //
        pdfInstance.UI.disableFeatures([
            Feature.SavedSignaturesTab,
            Feature.WatermarkPanel,
            Feature.ContentEdit,
            Feature.FilePicker,
            Feature.Print,
            Feature.Redaction,
            Feature.MouseWheelZoom,
            Feature.ContentEdit,
            Feature.Download,
        ]);
        
        //
        //     debugger
        //
        //     window.removeEventListener('keydown', () => {
        //         debugger
        //     }); // Замените `handler` на соответствующую функцию

    }, [pdfInstance, Feature]);

    useEffect(() => {
        if (!annotationManager) return

        // Identify user
        annotationManager.setCurrentUser(authData.user.displayName)

        /**
         * Disabled multiple annotations selection
         * Annotations listener properly handling now only one annotation
         */
        annotationManager.addEventListener('annotationSelected', (annotations) => {
            if (annotations.length > 1) {
                annotationManager.deselectAnnotations(annotations.splice(0))
            }

            // TODO: Later name it work only for customTools
            // annotations?.forEach((annotation) => {
            //     annotation.setRotationControlEnabled(false);
            // })
        })
    }, [annotationManager]);

    return (
        <>
            {lazyLoadingInProgress && (
                <Flex
                    style={{
                        position: 'absolute',
                        left: 16,
                        bottom: 32,
                        zIndex: 10,
                        padding: 8,
                        borderRadius: '50%',
                        background: 'white',
                    }}
                >
                    <Spin
                        size='small'
                    />
                </Flex>
            )}
            {tabLoading && (
                <Spin
                    size='large'
                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                />
            )}
            <div
                id='velt-pdf-viewer'
                data-velt-pdf-viewer='true'
                data-velt-iframe-container='true'
                style={{
                    'flex': 1,
                    'height': '100%',
                }}
                ref={viewer}
            />
        </>
    );
}

function openDatabase(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open('pdfViewerDB', 3);

        request.onupgradeneeded = (event) => {
            const db = request.result;

            // Delete old
            if (db.objectStoreNames.contains('files')) {
                db.deleteObjectStore('files');
            }

            // Create new
            const objectStore = db.createObjectStore('files', { keyPath: 'id' });

            // Create index
            if (!objectStore.indexNames.contains('timestamp')) {
                objectStore.createIndex('timestamp', 'timestamp', { unique: false });
            }
        };

        request.onsuccess = () => {
            resolve(request.result);
        };

        request.onerror = () => {
            reject(request.error);
        };
    });
}

async function saveFileToIndexedDB(id: string, blob: Blob): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction('files', 'readwrite');
    const store = transaction.objectStore('files');
    const timestamp = Date.now();

    // Ensure the object includes the key specified by the keyPath
    const fileRecord: StoredFile = { id, blob, timestamp };

    store.put(fileRecord);

    return new Promise<void>((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
    });
}

async function getFileFromIndexedDB(id: string): Promise<StoredFile | null> {
    const db = await openDatabase();
    const transaction = db.transaction('files', 'readonly');
    const store = transaction.objectStore('files');
    const request = store.get(id);

    return new Promise<StoredFile | null>((resolve, reject) => {
        request.onsuccess = () => resolve(request.result as StoredFile | null);
        request.onerror = () => reject(request.error);
    });
}

async function cleanUpOldFiles(): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction('files', 'readwrite');
    const store = transaction.objectStore('files');

    // Check if the 'timestamp' index exists before using it
    if (!store.indexNames.contains('timestamp')) {
        console.error('Index \'timestamp\' not found in object store.');
        return;
    }

    const index = store.index('timestamp');
    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

    return new Promise<void>((resolve, reject) => {
        const filesToDelete: string[] = [];
        const allFiles: StoredFile[] = [];

        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);

        const request = index.openCursor();

        request.onsuccess = (event: Event) => {
            const cursor = (event.target as IDBRequest).result as IDBCursorWithValue | null;

            if (cursor) {
                const { id, timestamp } = cursor.value as StoredFile;
                allFiles.push({ id, timestamp, blob: cursor.value.blob });

                if (timestamp < oneWeekAgo) {
                    filesToDelete.push(id);
                }
                cursor.continue();
            } else {
                // Sort files by timestamp to find the oldest
                allFiles.sort((a, b) => a.timestamp - b.timestamp);

                // If more than 50 files, mark the oldest for deletion
                while (allFiles.length > 50) {
                    const oldest = allFiles.shift();
                    if (oldest) {
                        filesToDelete.push(oldest.id);
                    }
                }

                // Delete marked files in parallel
                const deletePromises = filesToDelete.map(id => {
                    return new Promise<void>((resolve, reject) => {
                        const deleteRequest = store.delete(id);
                        deleteRequest.onsuccess = () => resolve();
                        deleteRequest.onerror = () => reject(deleteRequest.error);
                    });
                });

                Promise.all(deletePromises)
                    .then(() => filesToDelete.length && (console.info(`Successfully deleted ${filesToDelete.length} old files`)))
                    .catch(error => console.error('Error deleting files:', error));
            }
        };

        request.onerror = () => {
            reject(request.error);
        };
    });
}
