import { collection } from 'firebase/firestore';

import { db } from '../config.ts';

export type InputFileType =
    | 'currentYearFinancialStatement'
    | 'previousYearFinancialStatement'
    | 'registrationDocument'
    | 'legalDocument'
    | 'other'

export const TAB_ORDER: Record<InputFileType, number> = {
    currentYearFinancialStatement: 1,
    previousYearFinancialStatement: 2,
    registrationDocument: 3,
    legalDocument: 4,
    other: 5,
}

export interface InputFile {
    companyId: string;
    fileName: string
    fileType: InputFileType
    reportId: string
    extension: 'pdf'
    uploaded_at: Date
    uploadedBy: string
    storagePath: string
    entityId: string
    /**
     * storage_path can be modified after OCR
     */
    originalStoragePath:string
    /**
     * Full doc extraction
     */
    azureRawDocumentExtractPath: string
    /**
     * Page classification result
     */
    azurePageClassificationExtractPath: string
    template: string
    ocrApplied: boolean
}

export const INPUT_FILES_COLLECTION = 'inputFiles'

export const inputFilesRef = collection(db, INPUT_FILES_COLLECTION)
