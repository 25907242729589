import { collection, Timestamp } from 'firebase/firestore';

import { db } from '../config.ts';
import { getRequestQueries } from '@/api/utils/getRequestQueries';

export type ReportExtractedValueType = 'money' | 'manual' | 'number' | 'text' | 'pageNumber'

export interface ReportExtractedValues {
    companyId: string;
    /**
     * @deprecated
     */
    coords: [number, number, number, number];
    /**
     * Will be set by backend in case of manual extraction
     */
    coordinates?: {
        x0: number,
        y0: number,
        width: number
        height: number
    }
    normalizedValue?: number;
    originalValue: string;
    page: number;
    reportId: string;
    sourceFileName?: string;
    type: ReportExtractedValueType
    rejected?: boolean
    /**
     * @deprecated Used 'steps'
     */
    step: string
    steps: string[]
    createdAt?: Timestamp
}

export const REPORT_EXTRACTED_VALUES_COLLECTION = 'reportExtractedValues'

export const reportExtractedValuesRef = collection(db, REPORT_EXTRACTED_VALUES_COLLECTION)

export const {
    useFBItemsQuery: useReportExtractedValuesQuery,
} = getRequestQueries<ReportExtractedValues, {}>({
    url: REPORT_EXTRACTED_VALUES_COLLECTION,
})
