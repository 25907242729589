import { collection } from 'firebase/firestore';

import { CC_ReportPageLabel } from '../../../backend/configs/src/configs/steps/comercialCompanySteps.types.ts';
import { db } from '../config.ts';

interface ReportPageClassificationLabel {
    confidence?: number;
    include: boolean
    pageIndex: number
    pageLabel: CC_ReportPageLabel
}

export interface ReportPageClassification {
    companyId: string;
    labels: ReportPageClassificationLabel[]
}

export const REPORT_PAGE_CLASSIFICATION_COLLECTION = 'reportPageClassification'

export const reportPageClassificationRef = collection(db, 'reportPageClassification')
