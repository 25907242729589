import React, { useEffect, useState, useRef, createContext } from 'react'

import { Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react'
import { DocumentSnapshot, setDoc } from '@firebase/firestore';
import * as Sentry from '@sentry/react'
// import { VeltProvider } from '@veltdev/react';
import { Velt } from '@veltdev/types';
import { UserContact } from '@veltdev/types/app/models/data/user-contact.data.model';
import { Button, Flex, Result, Spin } from 'antd';
import { message } from 'antd/lib';
import { signInWithCustomToken } from 'firebase/auth';
import { doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { NavigateFunction } from 'react-router-dom';

import DocumentData = firebase.firestore.DocumentData;
import { AuthContextProps } from './AuthContext.types.ts'
import { Company, companyRef } from '../../../firestore/api/company.ts';
import { User, userRef } from '../../../firestore/api/user.ts';

import { firebaseAuth } from '@/firestore/config.ts';

import mixpanel from 'mixpanel-browser'

import { CONFIG } from '@/config.ts';
import { identifyMixPanel } from '@/components/containers/AuthContext/AuthContext.utils.ts';

if(CONFIG.MIXPANEL_KEY) {
    mixpanel.init(CONFIG.MIXPANEL_KEY, {
        debug: true,
        persistence: 'localStorage',
        record_sessions_percent: 100,
        record_mask_text_selector: '',
        record_mask_text_class: '',
        record_inline_images: true,
    })
}

export const getAuthConfig = (navigate: NavigateFunction): Auth0ProviderOptions => ({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    // Avoid redirect to auth0 in dev env
    // useRefreshTokens: process.env.NODE_ENV !== 'development',
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
    onRedirectCallback: (appState) => {
        navigate(appState?.returnTo || window.location.pathname)
    },
})

export type AuthData = {
    user: User,
    company: DocumentSnapshot<Company, Company>
    usersInCompany: User[]
}

export const AuthDataContext = createContext<AuthData>({
    user: null,
    company: null,
    usersInCompany: [],
})

/**
 * Logout if Any auth failed FB or Auth0
 */
export const AuthContext = (props: AuthContextProps) => {
    const { children } = props

    const [authContextState, setAuthContextState] = useState<AuthData>()
    const [isLoading, setIsLoading] = useState(true)
    const [veltClient, setVeltClient] = useState<Velt | null>(null)
    const { isAuthenticated, isLoading: isAuth0Loading, getAccessTokenSilently, user, logout, loginWithRedirect } = useAuth0()

    const appInited = useRef(false)

    useEffect(() => {
        if(!authContextState) return
        identifyMixPanel(authContextState)
    }, [authContextState]);

    // Waith for Auth ready and setup Velt client
    useEffect(() => {
        if (veltClient && authContextState) {
            const contacts: UserContact[] = authContextState!.usersInCompany.map(el => ({
                name: el.displayName,
                email: el.email,
                photoUrl: el.avatarUrl,
                userId: el.uid,
                visibility: 'group',
            }))

            const user = {
                userId: authContextState!.user.uid,
                name: authContextState!.user.displayName,
                photoUrl: authContextState!.user.avatarUrl,
                email: authContextState!.user.email,
                contacts,
                groupId: authContextState!.company.id,
            }

            veltClient.identify(user).catch(console.error)

            const userAuth = authContextState.user

            const userSentry = {
                id: userAuth?.uid,
                email: userAuth?.email,
                name: userAuth?.displayName,
            }

            // Global user
            Sentry.setUser(userSentry)

            Sentry.withScope((scope) => {
                scope.setTag('companyName', authContextState.company.data()?.name)
                scope.setTag('companyId', authContextState.company.id )

                // One event infor. Probably can be deleted.
                scope.setUser(userSentry)
            })
        }
    }, [veltClient, authContextState])
    
    useEffect(() => {
        if (isAuth0Loading || appInited.current || !user) {
            return
        }
        appInited.current = true

        ; (async () => {
            try {
                const idToken = await getAccessTokenSilently()

                // Validating Auth0 token and generates custom token for FB
                const response = await fetch('/api/config/firebase-token', {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                });
            
                const { firebaseToken } = await response.json()

                await signInWithCustomToken(firebaseAuth, firebaseToken);

                const docRef = doc(userRef, user.sub);

                await setDoc<DocumentData<User>, DocumentData<User>>(docRef, {
                    uid: user.sub,
                    email: user.email,
                    displayName: user.name,
                    avatarUrl: user.picture,
                }, {
                    merge: true,
                })

                // Need to get doc like this because company can be updated manually later
                const docSnapshot = await getDoc(docRef)

                const companyIds = docSnapshot.data()?.companyIds;

                if(companyIds?.length) {
                    const companyDocRef = doc(companyRef, companyIds[0])
                    const companySnapshot = await getDoc(companyDocRef)

                    const usersInCompanyStapshot = await getDocs(query(userRef, where('companyIds', 'array-contains-any', companyIds)))
                    const usersInCompany = usersInCompanyStapshot.docs.map(el => el.data())

                    setAuthContextState({
                        user: docSnapshot.data(),
                        company: companySnapshot,
                        usersInCompany: usersInCompany,
                    })
                }
            
                setIsLoading(false)
            } catch (error) {

                message.error('Error during authentication. Try again later')
                console.error(error)
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                })
            }
        })()
    }, [user, isLoading, isAuth0Loading])

    // Redirect to login if not authenticated
    useEffect(() => {
        if (!isAuth0Loading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname + window.location.search,
                },
            })
        }
    }, [isAuth0Loading, isAuthenticated])
    
    if (isLoading) {
        return (
            <Flex style={{ height: '100vh', width: '100vw' }} align='center' justify='center'>
                <Spin size='large'/>
            </Flex>
        )
    }

    if(!authContextState?.company) {
        return (
            <Flex style={{ height: '100vh', width: '100vw' }} align='center' justify='center'>
                <Flex vertical align='center' justify='center'>
                    <Result
                        status='403'
                        title='No access to the company'
                        subTitle='Please contact our sales team to gain access'
                    />
                    <Button
                        onClick={() => {
                            logout({
                                logoutParams: {
                                    returnTo: window.location.origin,
                                },
                            })
                        }}
                    >Logout</Button>
                </Flex>
            </Flex>
        )
    }

    return (
        // Type will be not empty after checking in the previous if statement
        // <VeltProvider
        //     apiKey={import.meta.env.VITE_VELT_KEY}
        //     onClientLoad={(client) => {
        //         setVeltClient(client)
        //     }}
        // >
        <AuthDataContext.Provider value={authContextState as unknown as AuthData}>
            {children}
        </AuthDataContext.Provider>
        // </VeltProvider>
    )
}
