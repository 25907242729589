import { useContext, useEffect, useRef } from 'react';

import { Core } from '@pdftron/webviewer';
import { useAsyncEffect } from 'ahooks';
import { and, doc, query, where } from 'firebase/firestore';
import { useCollection, useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { ReportAnnotation, reportAnnotationRef } from '@/firestore/api/reportAnnotation.ts';
import { ReportReview, reportReviewRef } from '@/firestore/api/reportReview.ts';
import { ReviewIdentifiedBlockConfidence } from '@/firestore/api/reviewIdentifiedBlock.ts';
import { useCurrentPage } from '@/hooks/useCurrentPage.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { AnnotationChangeSource } from '@/types/pdfLib/annotationChangeSource.ts';
import { ACTIVE_MONEY_VALUE_QUERY_PARAM } from '@/widgets/MoneyValuesNavigator/MoneyValuesNavigator.constants.ts';
import { AnnotationVariant, getAnnotationConfigByVariant } from '@/widgets/PdfViewer2/PdfViewer2.types.ts';
import { SUM_SELECT_COMPONENTS_QUERY_PARAM } from '@/widgets/SumSelect/SumSelect.contants.ts';

import Annotation = Core.Annotations.Annotation;

import { ReportTableObjects, reportTableObjectsRef } from '@/firestore/api/reportTableObjects.ts';
import { createAnnotation } from '@/widgets/PdfViewer2/PdfViewer2.utils.ts';
import { useFeatureOn } from '@/utils/isFeatureOn.ts';
import { useCreateAnnotations } from '@/utils/pdfViewer/createAnnotations.ts';

export const useApplyAnnotations = () => {
    const { id: docId } = useParams()

    const { annotationManager } = useViewerDocument()

    const [reportSnapshot, reportItemLoading] = useDocument<ReportReview>(doc(reportReviewRef, docId))

    const authData = useContext<AuthData>(AuthDataContext)

    const annotationCollectionFilter = query(reportAnnotationRef, and(
        where('companyId', '==', authData.company.id),
        where('reportId', '==', docId),
    ))

    const [reportAnnotationData, reportAnnotationDataLoading] = useCollectionData<ReportAnnotation>(
        annotationCollectionFilter,
    )

    const reportData = reportSnapshot?.data()

    const deleteAnnotForOtherSteps = (activeStep: string) => {
        const annotations = annotationManager.getAnnotationsList()

        // Delete for other steps
        const toDelete = annotations
            .filter((annotation) => {
                const stepFinished = reportData?.reviewStatus !== 'inProgress'
                const confidence = annotation.getCustomData('tickConfidence') as ReviewIdentifiedBlockConfidence
                const excluFromSymmaryFlag = annotation.getCustomData('excludeFromSummary') === 'true'
                const excludeFromSummary = confidence === 'neutral' || confidence === 'valid' || confidence === 'link' || excluFromSymmaryFlag

                if (stepFinished) {
                    return excludeFromSummary
                } else {
                    const step = annotation.getCustomData('relatedStep')

                    return step?.length && (step !== activeStep)
                }
            })

        const namesToDelete = toDelete.map((annotation) => annotation.getCustomData('toolName'))

        annotationManager?.deleteAnnotations(toDelete, {
            source: AnnotationChangeSource.temporaryDelete,
        })
    }

    const createNotAppliedAnnotations = async (activeStep: string) => {
        const annotations = annotationManager?.getAnnotationsList() || []
        const annotationsApplied = annotations.map((annotation) => annotation.Id)

        const regexToolName = new RegExp(`${CustomDataKey.toolName}:([a-zA-Z_]+)`);

        const annotWIthoutStep = reportAnnotationData
            ?.filter((annotaion) => {
                return !annotaion.stepKey;
            }) || []

        const annotationsNames = annotWIthoutStep.map((annotation) => annotation.anotation.match(regexToolName)?.[1])

        const annotForCurrentStep = reportAnnotationData
            ?.filter((annotaion) => {
                return activeStep && (annotaion.stepKey === activeStep);
            }) || []

        const annotationsForSummaryReport = reportAnnotationData
            ?.filter((reportAnnotatation) => {
                const stepFinished = reportData?.reviewStatus !== 'inProgress'

                if (!stepFinished) return false

                const regexConf = new RegExp(`${CustomDataKey.tickConfidence}:([a-zA-Z_]+)`);

                const normString = reportAnnotatation.anotation.replace(/&quot;/g, '')

                const confMatch = normString.match(regexConf);
                const nameMatch = normString.match(regexToolName);

                const confidence = confMatch?.[1] as ReviewIdentifiedBlockConfidence | undefined

                if (!confidence) return true

                const res = (confidence === 'invalid') ? true : false

                return res
            }) || []

        const toCreate =
            [...annotWIthoutStep, ...annotForCurrentStep, ...annotationsForSummaryReport]
                .filter((annotationData) => {
                    return !annotationsApplied.includes(annotationData.annotationId);
                })

        for (const annotationData of toCreate) {
            const annotations = await annotationManager?.importAnnotations(annotationData.anotation);
            annotations.forEach(a => {
                annotationManager?.redrawAnnotation(a);
            });
        }
    }

    /**
     * Re-apply annotations every step change
     */
    useEffect(() => {
        (async () => {
            if (!annotationManager || reportItemLoading || !reportData) return

            const activeStep = reportData?.currentStep

            deleteAnnotForOtherSteps(activeStep)
            await createNotAppliedAnnotations(activeStep)
        })()
    }, [reportData?.currentStep, annotationManager, reportItemLoading, reportData?.reviewStatus]);
}

/**
 * Will be triggered when block loaded fisrt time
 */
export const useCreateAnnotationsForIdentifiedBlocks = () => {

}

/**
 * Modified anntations based on query params
 * Sepearted from main render logic to keep better performance
 */
export const useAnnotStylesMofier = () => {
    const [sumComponentsParam] = useQueryParam(SUM_SELECT_COMPONENTS_QUERY_PARAM.name, SUM_SELECT_COMPONENTS_QUERY_PARAM.type)
    const [activeMoneyValues] = useQueryParam(ACTIVE_MONEY_VALUE_QUERY_PARAM.name, ACTIVE_MONEY_VALUE_QUERY_PARAM.type)

    const { annotationManager, pdfInstance } = useViewerDocument()
    const page = useCurrentPage()

    /**
     * Styling for SumSelect components
     */
    useEffect(() => {
        if (!annotationManager) return
        const annotList = annotationManager.getAnnotationsList()
        const annotListByPage = annotList.filter((annot) => annot.PageNumber === page)

        annotListByPage.forEach((annot) => {
            const snapshotId = annot.getCustomData(CustomDataKey.relatedSnapshotId)
            const annotationVariant = annot.getCustomData(CustomDataKey.annotationVariant)

            if (sumComponentsParam?.includes(snapshotId)) {

                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive].Opacity

                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValueActive)

                annotationManager.redrawAnnotation(annot)
            } else if (annotationVariant === AnnotationVariant.moneyValueActive) {
                annot.setBorderStyle(getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].BorderStyle)
                annot.Opacity = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue].Opacity

                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValue)

                annotationManager.redrawAnnotation(annot)
            }
        })
    }, [sumComponentsParam, annotationManager, page]);

    const prevActiveAnnotations = useRef<Annotation[]>([])

    /**
     * Styling for focused money value
     */
    useAsyncEffect(async () => {
        if (!annotationManager) return

        const annotList = annotationManager.getAnnotationsList()

        const defaultStyle = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValue]
        const activeStyle = getAnnotationConfigByVariant(pdfInstance)[AnnotationVariant.moneyValueActive]

        // Rollback unselecred annotations
        if (prevActiveAnnotations.current.length) {
            prevActiveAnnotations.current.forEach((annot) => {
                annot.setBorderStyle(defaultStyle.BorderStyle)
                annot.Opacity = defaultStyle.Opacity
                annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValue)
                annotationManager.redrawAnnotation(annot)
            })
        }

        const activeAnnotations = annotList.filter((annot) => {
            const snapshotId = annot.getCustomData(CustomDataKey.relatedSnapshotId)
            return activeMoneyValues?.includes(snapshotId)
        })

        // Select updated list of items
        activeAnnotations.forEach((annot) => {
            annot.setBorderStyle(activeStyle.BorderStyle)
            annot.Opacity = activeStyle.Opacity
            annot.setCustomData(CustomDataKey.annotationVariant, AnnotationVariant.moneyValueActive)
            annotationManager.redrawAnnotation(annot)
        })

        prevActiveAnnotations.current = activeAnnotations
    }, [
        page,
        activeMoneyValues,
        annotationManager,
    ]);
}

/**
 * Apply tables extraction result
 */
export const useTableDebug = () => {
    const { id } = useParams()
    
    const tableDebugOn = useFeatureOn('tableDebug')
    
    const { annotationManager , pdfInstance } = useViewerDocument()

    const [tablesSnap] = useCollection<ReportTableObjects,ReportTableObjects>(query(
        reportTableObjectsRef,
        where(
            'reportId',
            '==',
            id,
        ),
    ))
    
    const createAnnotations = useCreateAnnotations()

    useAsyncEffect(async () => {
        if(!tableDebugOn || !createAnnotations) return

        try {
            const tableSnaps = tablesSnap?.docs
        
            if(!tableSnaps?.length || !annotationManager) return

            const annotationsList = annotationManager.getAnnotationsList()
            let newAnnotations: Annotation[] = []

            for(const tableSnap of tableSnaps) {
                const tableData = tableSnap.data()
                const { boundingRegions, rowConfigs } = tableData
                
                const annotation = await createAnnotation({
                    createAnnotations,
                    doNotCreate: true,
                    annotationsList,
                    annotationManager,
                    pdfInstance,
                    pageIndex: boundingRegions[0].page,
                    coordinates: [boundingRegions?.[0].x, boundingRegions?.[0].y, boundingRegions[0].width, boundingRegions[0].height],
                    showInNotesPanel: false,
                    relatedSnapshotId: tableSnap.id,
                    annotationVariant: AnnotationVariant.tableBorder,
                    reply: 'Table',
                    excludeFromSummary: true,
                    skipEventHandlers: true,
                })

                const totalRows = rowConfigs?.filter((row) => row.total) || []

                if(totalRows.length) {
                    for(const row of totalRows) {
                        const rowIndex = rowConfigs?.findIndex((r) => r.separator === row.separator)
                        const prevTableRow = rowConfigs[rowIndex - 1]

                        const height = prevTableRow?.separator ? ( row.separator - prevTableRow.separator) : (boundingRegions[0].y + boundingRegions[0].height - row.separator)
                        const coords = [boundingRegions[0].x, row.separator - height , boundingRegions[0].width, height ]

                        const annotationRow = await createAnnotation({
                            createAnnotations,
                            doNotCreate: true,
                            annotationsList,
                            annotationManager,
                            pdfInstance,
                            pageIndex: boundingRegions[0].page,
                            coordinates: coords,
                            showInNotesPanel: false,
                            relatedSnapshotId: tableSnap.id + '_row_' + row.separator,
                            annotationVariant: AnnotationVariant.totalRow,
                            reply: 'Separator',
                            excludeFromSummary: true,
                            skipEventHandlers: true,
                        })

                        newAnnotations.push(annotationRow)
                    }
                }

                newAnnotations.push(annotation)
            }
            
            const filteredAnnotaions = newAnnotations.filter(Boolean)

            createAnnotations(filteredAnnotaions)
            newAnnotations = []
        } catch (e) {
            console.error(e)
        }
    }, [tablesSnap, annotationManager, tableDebugOn, createAnnotations]);
}
