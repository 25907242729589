interface Coordinates {
    x0: number;
    y0: number;
    width: number;
    height: number;
    page: number;
}

/**
 * Check if two rectangles intersect with percentage tolerance
 * @param rect1 First rectangle coordinates
 * @param rect2 Second rectangle coordinates
 * @param tolerance Max PX in coordinates diff
 * @returns boolean - true if intersection percentage is greater than tolerance
 */
export const calculateRectIntersection = (rect1: Coordinates, rect2: Coordinates, tolerance: number = 2): boolean => {
    // Early return if rectangles are on different pages
    if (rect1.page !== rect2.page) {
        return false;
    }

    const x0Diff = Math.abs(rect1.x0 - rect2.x0);
    const y0Diff = Math.abs(rect1.y0 - rect2.y0);
    const widthDiff = Math.abs(rect1.width - rect2.width);
    const heightDiff = Math.abs(rect1.height - rect2.height);

    const totalDiff = x0Diff + y0Diff + widthDiff + heightDiff;
    
    return totalDiff <= tolerance;
} 
